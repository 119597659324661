import React from 'react';
import classNames from 'classnames';
import Text from 'components/Text';
import ImgWithLoader from 'components/ImgWithLoader';
import { statisticParserNameConfig, parserNamesWithoutImgs } from 'consts';
import { Link } from 'react-router-dom';
import BadgeContainer from 'components/BadgeContainer';
import { handleHtmlSpecSymbols } from 'helpers';
import CommentsComponent from 'components/CommentsComponent';
import styles from './styles.module.scss';

const PostItem = (props) => {
  const {
    additionalStyles = {}, post, parserName, sortingPostfix,
  } = props;
  const {
    title, author, datePublished, description, link, imgUrl, category, statistic, tags = [],
  } = post;

  const params = new URLSearchParams(window.location.search);
  const isDebug = params.get('debugPost') === '1';

  const renderAdditionaldata = () => {
    if (!isDebug) {
      return null;
    }

    const configName = statisticParserNameConfig[parserName];
    const statisticParser = (statistic || {})[configName];

    const statWithoutParser = { ...statistic };
    delete statWithoutParser[configName];

    return (
      <div>
        <h3>General statistics</h3>
        <pre className={styles.statisticJson}>{JSON.stringify(statWithoutParser, null, 2)}</pre>
        <h3>
          Statistic of
          {parserName}
        </h3>
        <pre className={styles.statisticJson}>{JSON.stringify(statisticParser, null, 2)}</pre>
      </div>
    );
  };

  const renderTags = () => {
    if (!tags.length) {
      return null;
    }

    return (
      <div className={styles.tags}>
        { tags.map((tag) => (
          <span key={`${tag}_${Math.random().toFixed(6)}`} className={styles.tag}>{tag}</span>
        )) }
      </div>
    );
  };

  const categoryJSX = category ? (

    <Link to={`/parser-data/${parserName}/${encodeURIComponent(category.name)}?${sortingPostfix}`}>

      <Text
        content={category.name}
        additionalStyles={classNames(styles.trendsStyle,
          { [styles.displayed]: category.name })}
      />
    </Link>
  ) : '';

  return (
    <div
      className={classNames('col-12 col-md-10 col-lg-8', styles.postItemCont, additionalStyles, { [styles.withDebug]: isDebug })}
    >
      {!parserNamesWithoutImgs.includes(parserName) && (
        <a
          className={styles.postItemFrameCont}
          target="_blank"
          href={link}
          rel="noreferrer"
        >
          <ImgWithLoader
            imgUrl={handleHtmlSpecSymbols(imgUrl)}
            parserName={parserName}
            imgClass={styles.imgStyle}
          />
        </a>
      )}
      <CommentsComponent parserName={parserName} data={post} cn={styles.commentButton} />
      <div className={styles.postItemRightCont}>

        {categoryJSX}
        { author && isDebug && (
        <p>
          Author:
          {author}
        </p>
        ) }
        { datePublished && isDebug && (
        <p>
          Date Published:
          {datePublished}
        </p>
        ) }
        <div className={styles.postItemText}>
          <a
            target="_blank"
            href={link}
            rel="noreferrer"
          >
            <Text content={title || description} />
          </a>
        </div>

        {renderTags()}
        {renderAdditionaldata()}
        <BadgeContainer data={post} parserName={parserName} />
      </div>
    </div>
  );
};

export default PostItem;
