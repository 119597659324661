export const SET_MODAL = 'SET_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

const initialState = {
  content: null,
};

export const setModal = (data) => ({
  type: SET_MODAL,
  data,
});

export const closeModal = (data) => ({
  type: CLOSE_MODAL,
  data,
});

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL:
      return { ...state, content: action.data };
    case CLOSE_MODAL:
      return { ...state, content: null };
    default: return state;
  }
};

export default modal;
