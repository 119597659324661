import React, { useEffect, useState } from 'react';
import InnerPageWrapper from 'components/InnerPageWrapper';
import ErrorComp from 'components/ErrorComp';
import Loading from 'components/Loading';
import { useSelector } from 'react-redux';
import RequestsApi from 'services/requestsApi';
import { TIME_FILTER_CONFIG } from 'consts';
import TrendsItem from '../../components/TrendsItem';
import styles from './styles.module.scss';

const TrendsPage = (props) => {
  const { match } = props;
  const { parserName } = match.params;
  const [arrivedData, setArrivedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page] = useState(1);
  const [parser, setParser] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const timeFilter = useSelector((store) => store.page.timeFilter);
  const searchString = useSelector((store) => store.page.search);
  const fvar = `${timeFilter.value}${TIME_FILTER_CONFIG[timeFilter.type]}`;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const trends = await RequestsApi.getTrendsByParserName(parserName, fvar, page);

      setLoading(false);

      if (trends.error) {
        setError(trends.error);
      }

      if (trends.cont) {
        setParser(trends.parser);
        setArrivedData(trends.cont);
        setFilteredData(trends.cont);
        setError(null);
      }
    })();
  }, [timeFilter, page, parserName, fvar]);

  useEffect(() => {
    if (arrivedData.length === 0) return;
    const newParsersData = arrivedData.filter((v) => {
      const search = searchString.toLowerCase().trim();
      const searchVariant = v.name;
      return searchVariant && searchVariant.toLowerCase().indexOf(search) !== -1;
    });
    setFilteredData(newParsersData);
  }, [searchString, arrivedData]);

  const content = filteredData.map((v) => (
    <TrendsItem
      key={`trends_id_${v.id}`}
      trend={v}
      parserName={parserName}
      additionalStyles={styles.trendsItemStyle}
    />
  ));

  return (
    <InnerPageWrapper
      title={parser.title}
      color={parser.color}
      parserId={parser.parserId}
      waitingState={loading || error}
      logoLink={`/trends/${parserName}`}
    >
      {loading ? <Loading /> : content}
      {!loading && error && <ErrorComp error={error} />}
    </InnerPageWrapper>
  );
};

export default TrendsPage;
