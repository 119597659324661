import React, { useEffect, useState } from 'react';
import { setModal } from 'store/reducers/modal';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './styles.module.scss';
import WebSocketApi from '../../services/webSocketApi';

const CommentsComponent = (props) => {
  const { data, parserName, cn } = props;
  const dispatch = useDispatch();
  const { comments: count } = data;
  const [comments, setComments] = useState(count);
  const eventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setModal({
      data, parserName,
    }));
  };

  useEffect(() => {
    WebSocketApi.subscribeToCommentsCounter(data.id, (messageData) => {
      setComments(messageData.post.comments);
    });
    return () => {
      WebSocketApi.unSubscribeToCommentsCounter(data.id);
    };
  }, [data.id]);

  return (
    <div
      className={classNames(styles.triggerButton, { [cn]: cn }, { triggerButtonEmpty: !comments })}
      role="button"
      tabIndex={0}
      onClick={eventHandler}
      onKeyDown={eventHandler}
    >
      <img
        alt="badge_altimg"
        src="/icons/badge/badge-write.svg"
      />
      { comments || ''}
    </div>
  );
};

export default CommentsComponent;
