import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/Text';
import styles from 'components/TrendsItem/styles.module.scss';
import classNames from 'classnames';

const TrendsItem = (props) => {
  const { trend, parserName, additionalStyles = {} } = props;
  const { name } = trend;

  return (
    <Link className={classNames('col-8', styles.trendsItemCont, additionalStyles)} to={`/parser-data/${parserName}/${encodeURI(name)}`}>
      <div className={styles.hashtag}>#</div>
      <Text content={name} additionalStyles={styles.text} />
    </Link>
  );
};

export default TrendsItem;
