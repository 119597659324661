import React from 'react';
import crossIcon from 'assets/icons/notiff-cancel.svg';
import styles from './styles.module.scss';

const NotiffComponent = ({ text = 'simple text', parserId = '' }) => {
  const clickHandler = (e) => {
    if (!parserId) {
      return;
    }
    if (e.name !== 'removal') {
      const elem = document.getElementById(`parser_Id_${parserId}`);
      elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  // eslint-disable-next-line no-return-assign
  const clickDisp = (e) => e.name = 'removal';

  return (
    <div
      role="button"
      tabIndex="0"
      className={styles.notiffContainer}
      onClick={clickHandler}
      onKeyDown={clickHandler}
    >
      <div className={styles.notiffTextPart}>
        <div className={styles.notiffText}>
          {text}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div
          role="button"
          tabIndex="0"
          onKeyDown={clickDisp}
          className={styles.notiffButton}
          onClick={clickDisp}
        >
          <img src={crossIcon} alt="remove_notification" />
        </div>
      </div>
    </div>
  );
};

export default NotiffComponent;
