import React, { useEffect, useState } from 'react';
import InnerPageWrapper from 'components/InnerPageWrapper';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import PostItem from 'components/PostItem';
import PostItemSm from 'components/PostItemSm';
import ErrorComp from 'components/ErrorComp';
import PostsSkeleton from 'components/PostsSkeleton';
import RequestsApi from 'services/requestsApi';
import { TIME_FILTER_CONFIG, SORTING_OPTIONS_NAME } from 'consts';
import { prepareArrayOfObjectsForQuerystring } from 'helpers';
import { setCurrentParserInfo } from '../../store/reducers/page';
import styles from './styles.module.scss';
import WS from '../../services/webSocket';
import MyWebSocket from '../../services/webSocketApi';

const PostsPage = (props) => {
  const { match } = props;
  const { parserName } = match.params;
  const [arrivedData, setArrivedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLargeSize, setIsLargeSize] = useState(true);
  const [error, setError] = useState(null);
  const [parser, setParser] = useState('');
  const [loading, setLoading] = useState(true);
  const [page] = useState(1);
  const timeFilter = useSelector((store) => store.page.timeFilter);
  const searchString = useSelector((store) => store.page.search);
  const dispatch = useDispatch();

  const parsed = queryString.parse(window.location.search);

  const clkHandler = (val) => {
    setIsLargeSize(val);
  };

  useEffect(() => {
    RequestsApi.getParserInfo(parserName).then((parserInfo) => {
      const newParserInfo = { ...parserInfo };
      if (parsed.sortby_field
        && parsed.period_type
        && parsed.period_value
        && parsed.sortby_direction) {
        const options = {
          period: {
            unit: parsed.period_type,
            value: parsed.period_value,
          },
          sortBy: {
            field: parsed.sortby_field,
            dir: parsed.sortby_direction,
          },
        };
        newParserInfo.options = options;
      } else {
        newParserInfo.options = {
          period: {
            value: timeFilter.value,
            unit: TIME_FILTER_CONFIG[timeFilter.type],
          },
          sortBy: { ...newParserInfo.sortingOptions.default[0] },
        };
      }
      if (!MyWebSocket.isInit()) {
        const wsInstance = new WS();
        MyWebSocket.init(wsInstance);
      }
      setParser(newParserInfo);
      dispatch(setCurrentParserInfo(newParserInfo));
    });
  }, [parserName,
    parsed.period_type,
    parsed.period_value,
    parsed.sortby_direction,
    parsed.sortby_field,
    timeFilter.type,
    timeFilter.value]);

  useEffect(() => {
    if (!parser) {
      return;
    }
    const fvar = `${parser.options.period.value}${parser.options.period.unit}`;

    (async () => {
      setLoading(true);
      const data = await RequestsApi.getPostsByParserId(
        parser.id,
        fvar,
        page,
        prepareArrayOfObjectsForQuerystring(
          SORTING_OPTIONS_NAME,
          [parser.options.sortBy],
        ),
      );

      setArrivedData(data);
      setFilteredData(data);
      setError(null);
      setLoading(false);
    })();
  }, [timeFilter, page, parser]);

  useEffect(() => {
    if (arrivedData.length === 0) return;
    const newParsersData = arrivedData.filter((v) => {
      const search = searchString.toLowerCase().trim();
      const searchVariant = v.title || v.description;
      return searchVariant && searchVariant.toLowerCase().indexOf(search) !== -1;
    });
    setFilteredData(newParsersData);
  }, [searchString, arrivedData]);

  const filterPosts = (timeOptions) => {
    const newParser = JSON.parse(JSON.stringify(parser));
    newParser.options = timeOptions;
    setParser(newParser);
  };

  const PostComponent = isLargeSize ? PostItem : PostItemSm;
  let sortingPostfix;
  if (parser.options) {
    sortingPostfix = `sortby_field=${parser.options.sortBy.field}&sortby_direction=${parser.options.sortBy.dir}&period_type=${parser.options.period.unit}&period_value=${parser.options.period.value}`;
  }

  const content = filteredData.map((v) => (
    <PostComponent
      key={`post_sm_id_${v.id}}`}
      post={v}
      parserName={parserName}
      additionalStyles={styles.postItemStyle}
      sortingPostfix={sortingPostfix}
    />
  ));
  return (
    <InnerPageWrapper
      title={parser.title}
      color={parser.color}
      parserId={parser.parserId}
      switchButtonShow
      itemSize={isLargeSize}
      itemClkHandler={clkHandler}
      waitingState={loading}
      parserInfo={parser}
      logoLink={`/parser-data/${parserName}`}
      filterPosts={filterPosts}
      sortingPostfix={sortingPostfix}
    >
      {error && <ErrorComp error={error} />}
      {loading ? <PostsSkeleton /> : content}

    </InnerPageWrapper>
  );
};

export default PostsPage;
