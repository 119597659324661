import { store } from 'react-notifications-component';
import { NOTIF_DURATION } from '../consts';

class NotificationService {
  constructor() {
    this.store = store;

    this.defaultProps = {
      insert: 'bottom',
      container: 'bottom-right',
      type: 'success',
    };
  }

  addNotification(message, title, props = {}) {
    this.store.addNotification({
      dismiss: {
        duration: NOTIF_DURATION,
      },
      title,
      message,
      ...this.defaultProps,
      ...props,
    });
  }
}

export default new NotificationService();
