import axios from 'axios';
import storageService from 'services/StorageService';

class RequestsApi {
  constructor() {
    this.recursionCounter = 0;
  }

  async authRequest(url, method, bodyObj = {}) {
    const methodName = method.toLowerCase().trim();
    const accessToken = storageService.get('accessToken');
    const refreshToken = storageService.get('refreshToken');
    let result = null;
    if (!(accessToken && refreshToken)) {
      document.location.href = '/login';
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const axiosParams = [];
    axiosParams.push(url);
    if (methodName === 'post') {
      axiosParams.push(bodyObj);
    }
    axiosParams.push(config);
    try {
      result = await axios[methodName](...axiosParams);
      if (result && (result.status >= 200 && result.status <= 299)) {
        return result;
      }
    } catch (e) {
      if (e.response && e.response.status === 403) {
        storageService.clear();
        document.location.href = '/login?errorMessage=Users account is not active';
      }
      if (e.response && e.response.status === 401) {
        const st = await RequestsApi.refreshAuthTokens();
        if (st && this.recursionCounter < 2) {
          this.recursionCounter += 1;
          return this.authRequest(url, method, bodyObj);
        }
        this.recursionCounter = 0;
        storageService.clear();
        document.location.href = '/login';
      }
      throw e;
    }
    return null;
  }

  static async saveAuthTokens(code, redirectUrl) {
    if (!code && !redirectUrl) {
      return null;
    }
    const conf = { code, redirectUrl };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/google-auth`, conf);
      if (response && response.status === 200) {
        const { token, refreshToken } = response.data;
        storageService.add('accessToken', token);
        storageService.add('refreshToken', refreshToken);
      }
      return response;
    } catch (err) {
      return err;
    }
  }

  static async refreshAuthTokens() {
    const refreshToken = storageService.get('refreshToken');
    if (!refreshToken) {
      return null;
    }
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token`, { refreshToken });
      if (result && result.status === 200) {
        const { token, refreshToken: newRefreshToken } = result.data;
        storageService.add('refreshToken', newRefreshToken);
        storageService.add('accessToken', token);
      }
      return result;
    } catch (e) {
      return null;
    }
  }

  async getParserInfo(parserName) {
    try {
      const result = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/source/${parserName}`, 'get');
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return error;
    }
  }

  async getLastJobStatus(crawlerId, parserType) {
    try {
      const result = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/source/last-job-status/${crawlerId}/${parserType}`, 'get');
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return error;
    }
  }

  async createSource(data) {
    const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/source`, 'post', data);
    return res;
  }

  async deleteSource(sourceId) {
    const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/source/${sourceId}`, 'delete');
    return res;
  }

  static handleErrors(error) {
    if (error.response) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }

  async getPostsByParserId(parserId, filterTime, page, sortingOptions) {
    try {
      const rawData = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/post/${parserId}?filterTime=${filterTime}&page=${page}&limit=100${sortingOptions}`, 'get');
      return rawData.data;
    } catch (error) {
      return RequestsApi.handleErrors(error);
    }
  }

  async getTrendsByParserName(parserName, filterTime, page) {
    const parser = await this.getParserInfo(parserName);
    if (parser.message) {
      return {
        error: parser.message,
      };
    }
    try {
      const rawData = this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/category/${parser.id}?filterTime=${filterTime}&page=${page}&limit=27`, 'get');
      return {
        cont: rawData.data,
        parser,
      };
    } catch (error) {
      return RequestsApi.handleErrors(error);
    }
  }

  async getPostsByCategoryName(parser, categoryName, filterTime, page, sortingOptions) {
    try {
      const categoryId = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/category/parser/${categoryName}/${parser.name}`, 'get');
      const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/post/${parser.id}?filterTime=${filterTime}&categoryId=${categoryId.data.id}&page=${page}&limit=27${sortingOptions}`, 'get');
      return res.data;
    } catch
    (e) {
      return RequestsApi.handleErrors(e);
    }
  }

  async executeParserUpdate(parserType) {
    const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/execute/${parserType}`, 'post');
    return res;
  }

  async checkExecutionUpdate(parserType, requestId) {
    const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/execute/${parserType}/${requestId}`, 'get');
    return res;
  }

  async deleteTemplate(templateId) {
    const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/templates/${templateId}`, 'delete');
    return res;
  }

  async saveTemplate(template) {
    const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/templates`, 'post', template);
    return res;
  }

  async getTemplates() {
    const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/templates`, 'get');
    return res;
  }

  async getComments(postId, page) {
    const res = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/comment/${postId}?page=${page}`, 'get');
    return res;
  }

  async healthCheckRequest() {
    try {
      const result = await this.authRequest(`${process.env.REACT_APP_API_BASE_URL}/healthcheck`, 'get');
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return error;
    }
  }
}
export default new RequestsApi();
