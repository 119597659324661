import React, { useState } from 'react';
import styles from './styles.module.scss';
import { DEFAULT_PARSER_ICON_LINK } from '../../../consts';

const SelectedFiltersItem = (props) => {
  const {
    text, href, iconSrc, textVisible = true,
  } = props;

  const [imgLink, setImgLink] = useState(iconSrc);

  const dontLoadedImg = () => {
    setImgLink(DEFAULT_PARSER_ICON_LINK);
  };

  const scrollToWidget = () => {
    const elem = document.getElementById(href);
    elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  return (
    <div className={styles.selectedFiltersItemCont}>
      <div
        tabIndex="0"
        role="button"
        className={styles.mainWrapper}
        onClick={scrollToWidget}
        onKeyDown={scrollToWidget}
      >
        <div data-hoverelem="true" className={styles.hoverElem}>
          {text}
        </div>
        <div className={styles.badgeIconStyle}>
          <img src={imgLink} alt="icon-pars-sr" onError={dontLoadedImg} />
        </div>
        { textVisible && (
          <div className={styles.badgeTextStyle}>
            {text}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(SelectedFiltersItem);
