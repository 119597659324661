import React from 'react';
import { setModal } from 'store/reducers/modal';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import UserAvatar from '../UserAvatar';

const CommentNotification = (props) => {
  const { data } = props;
  const { comment, post } = data;

  const dispath = useDispatch();

  const openModal = () => {
    dispath(setModal({
      data: post,
    }));
  };
  return (
    <div className={styles.commentNotification}>

      <button
        type="button"
        className={styles.closeButton}
      >
        <img
          alt="badge_altimg"
          src="/icons/modal/close-modal-icon.svg"
        />
      </button>
      <button type="button" className={styles.notificationContent} onClick={openModal} onKeyPress={openModal}>

        <UserAvatar user={comment.user} />
        <div className={styles.notificationComment}>
          {comment.body}
        </div>

      </button>

      <a href={post.link} className={styles.postContent} target="_blank" rel="noreferrer">
        {post.imgUrl && <img src={post.imgUrl} alt={post.description} />}
        <span className={styles.postContentText}>
          {post.description}
        </span>
      </a>
    </div>
  );
};

export default CommentNotification;
