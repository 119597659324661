import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Button = (props) => {
  const {
    onClick,
    icon = null,
    children: text,
    additionalStyles = {},
    additionalTextStyles = {},
    disabled = false,
  } = props;
  return (
    <button type="button" onClick={disabled ? null : onClick} className={classNames(styles.button, additionalStyles)}>
      { icon && <img src={icon} alt="" /> }
      { text && (
        <span
          className={classNames(
            styles.buttonText,
            additionalTextStyles,
          )}
        >
          {text}
        </span>
      ) }
    </button>
  );
};

export default Button;
