import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Overlay from 'components/Overlay';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

const SaveTemplateComponent = (props) => {
  const [notValidName, setNotValidName] = useState(false);
  const {
    show = false,
    cancelHandler = null,
    saveHandler = null,
    waiting,
    error,
    inputValue,
  } = props;
  const els = useRef(null);
  const timeout = useRef();
  const allTemplates = useSelector((store) => store.templates.allTemplates);

  useEffect(() => {
    if (show) {
      setNotValidName(false);
    }
  }, [show]);

  const checkForOriginalTemplateName = (name) => {
    const names = allTemplates.map((v) => v.name);

    return names.includes(name);
  };

  const stopHandler = (e) => {
    e.stopPropagation();
  };

  const saveClkHandler = () => {
    if (checkForOriginalTemplateName(inputValue)) {
      setNotValidName(true);
      return;
    }
    saveHandler(inputValue);
  };

  const stopHandlerForKeys = (e) => {
    if (e.key === 'Enter') {
      saveClkHandler();
    }
    if (e.key === 'Escape') {
      cancelHandler();
    }

    e.stopPropagation();
  };

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (els.current && show) {
      (() => {
        const inputStr = els.current;
        timeout.current = setTimeout(() => {
          inputStr.focus();
        }, 500);
      })();
    }
  }, [show]);

  return (

    <Overlay show={show} clickHandler={cancelHandler}>
      <div
        role="button"
        tabIndex="0"
        className={classNames(styles.mainFrame, { [styles.showFrame]: show })}
        onClick={(e) => stopHandler(e)}
        onKeyDown={(e) => stopHandlerForKeys(e)}
      >
        <div className={classNames(styles.contLine, { [styles.screenHide]: error })}>
          <div className={styles.titleCont}>
            {waiting ? 'Saving new template ...' : 'Save new template?'}
          </div>
          <div className={styles.templateName}>
            {waiting ? '' : `${inputValue}`}
          </div>

          <div className={
            classNames(styles.validationLabel,
              { [styles.validationLabelActive]: notValidName })
          }
          >
            The templates name is invalid or already exists. Please type another name.
          </div>

          <div className={styles.buttonContainer}>
            <Button
              disabled={waiting}
              onClick={cancelHandler}
              additionalStyles={
                classNames(styles.buttons,
                  styles.cancelButton,
                  { [styles.disabledButton]: waiting })
              }
            >
              Cancel
            </Button>
            <Button
              disabled={waiting}
              onClick={saveClkHandler}
              additionalStyles={classNames(styles.buttons, { [styles.disabledButton]: waiting })}
            >
              Save
            </Button>
          </div>
        </div>

        <div className={classNames(styles.errorScreen, { [styles.screenHide]: !error })}>
          {error}
        </div>

      </div>
    </Overlay>

  );
};

export default SaveTemplateComponent;
