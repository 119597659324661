import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import ParsersWidget from 'components/ParsersWidget';
import LoadingComponent from 'components/LoadingComponent';
import Header from 'components/Header';
import Footer from 'components/Footer';
import storageService from 'services/StorageService';
import appInit from 'services/appInit';
import FilterAndSortingBar from 'components/FilterAndSortingBar';
import styles from './styles.module.scss';
import { clearCurrentParserInfo } from '../../store/reducers/page';
import WS from '../../services/webSocket';
import MyWebSocket from '../../services/webSocketApi';

const MainPage = (props) => {
  const { history } = props;
  const [loaded, setLoaded] = useState(false);
  const [filterBarShow, setFilterBarShow] = useState(false);
  const { allParsers, allSources } = useSelector((st) => st.parsers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCurrentParserInfo());
    const aToken = storageService.get('accessToken');
    const rToken = storageService.get('refreshToken');
    if (!(aToken && rToken)) {
      history.push('/login');
      return;
    }

    (async () => {
      const m = await appInit();
      if (!MyWebSocket.isInit()) {
        const wsInstance = new WS();
        MyWebSocket.init(wsInstance);
      }
      setLoaded(m);
    })();
  }, [history]);

  let renderData = [];
  if (allParsers) {
    const parserOrder = [...new Set(
      allSources.filter(({ isMarked }) => isMarked).map(({ parserId }) => parserId),
    )];

    const sorterParsers = [...allParsers]
      .filter(({ isMarked }) => isMarked)
      .sort((a, b) => parserOrder.indexOf(a.id) - parserOrder.indexOf(b.id));

    renderData = sorterParsers.reduce((accum, data) => {
      accum.push(
        <div className="col-xl-10 col-12" key={`key_for_parser_widget_${data.title}`} id={`parser_Id_${data.parser}`}>
          <ParsersWidget parser={data} />
        </div>,
      );

      return accum;
    }, []);
  }

  const showHandler = () => {
    setFilterBarShow(!filterBarShow);
  };

  return (
    <>
      <div className={classNames(styles.MPCont, { [styles.contHidden]: !loaded })}>
        <title>Brain-lm</title>
        <Header showHandler={showHandler} />
        <FilterAndSortingBar show={filterBarShow} />
        <div className={classNames(styles.mainPageContentCont, 'row')}>
          {renderData}
        </div>
        <Footer />
      </div>
      <LoadingComponent text="Loading ..." show={!loaded} />
    </>
  );
};
export default MainPage;
