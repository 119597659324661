import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import ParserSortWidget from './ParserSortWidget';
import TemplatesComponent from './TemplatesComponent';
import SelectedFilters from './SelectedFilters';

const FilterAndSortingBar = ({ show }) => {
  const allParsers = useSelector((store) => store.parsers.allParsers);
  const [innerShow, setInnerShow] = useState(true);

  useEffect(() => {
    setInnerShow(!innerShow);
  }, [show]);

  // eslint-disable-next-line
  const [listBundle, setListBundle] = useState([]);
  const [parsersInfo, setParsersInfo] = useState();

  const changeListBundle = (listBundleValue) => {
    setListBundle(listBundleValue);
  };

  const changeParsersInfo = (parsersInfoObj) => {
    setParsersInfo(parsersInfoObj);
  };

  const changeVisibility = () => {
    setInnerShow(false);
  };

  const sortedParsers = useMemo(() => [...allParsers].sort((a, b) => {
    if (a.title < b.title) { return -1; }
    if (a.title > b.title) { return 1; }
    return 0;
  }), [allParsers]);

  const parsers = useMemo(() => sortedParsers
    .filter(({ parser }) => listBundle.includes(parser))
    .map(({ title }) => title),
  [sortedParsers, listBundle]);

  return (
    <div className={classNames('row', styles.filterBarRow)}>
      <div className={classNames('col-xl-10 p-0', styles.filterBarBadgesCont)}>
        <SelectedFilters listBundle={parsers} parsersInfo={parsersInfo} show={!innerShow} />
      </div>
      <div
        className={classNames('col-xl-10 p-0', styles.filterBarCont, { [styles.filterBarContNotShow]: !innerShow })}
      >
        <div className="row">
          <div className={classNames('col-12 d-flex', styles.widgWrapCont)}>
            <ParserSortWidget
              changeParserList={changeListBundle}
              setParsersInfo={changeParsersInfo}
              setVisibility={changeVisibility}
            />
            <TemplatesComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterAndSortingBar;
