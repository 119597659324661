import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import verticalMoreIcon from 'assets/icons/vertical-more-icon.svg';
import cancelIcon from 'assets/icons/cross-dnd-icon.svg';
import styles from './styles.module.scss';
import { TYPE1 } from '../ItemTypes';
import { DEFAULT_PARSER_ICON_LINK } from '../../../consts';

const ParserItem = (props) => {
  const {
    children,
    itemHoverFunc,
    visible = true,
    itemDelete,
    deleteShow = false,
    index,
    iconSrc,
    endDragFunc,
    dblClick,
    forceBackground,
    useHighlight = false,
  } = props;

  const [, drag] = useDrag({
    item: {
      type: TYPE1,
      value: children,
      index,
    },
    end: () => endDragFunc(children),
  });

  const [imgLink, setImgLink] = useState(iconSrc);
  const [highlight, setHighlight] = useState(false);
  const timeoutRef = useRef();

  useEffect(() => {
    if (!useHighlight) {
      return;
    }

    setHighlight(true);
    timeoutRef.current = setTimeout(() => {
      setHighlight(false);
    }, 2000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [useHighlight]);

  const [, drop] = useDrop({
    accept: TYPE1,
    hover: (item) => itemHoverFunc(item.value, children),
  });

  const opacityValue = visible ? 1 : 0;
  const deleteShowValue = deleteShow ? 'flex' : 'none';

  const dontLoadedImg = () => {
    setImgLink(DEFAULT_PARSER_ICON_LINK);
  };

  return (
    <div
      onDoubleClick={() => dblClick(children)}
      ref={drag}
      className={
        classNames(
          styles.itemStyle,
          { [styles.itemForceBackground]: forceBackground },
          { [styles.highlight]: highlight },
        )
      }
      style={{ opacity: opacityValue }}
    >
      <div ref={drop} className={styles.itemStyleWrapper}>
        <div className={styles.moreVerticalStyle}>
          <img src={verticalMoreIcon} alt="more-vertical-icon" />
        </div>
        <div className={styles.parserIconContainer}>
          <img src={imgLink} alt="parser-icon" onError={dontLoadedImg} />
        </div>
        <div className={styles.innerBlock}>
          {children}
        </div>
        <div
          role="button"
          tabIndex="0"
          className={styles.itemDeleteStyle}
          onClick={() => itemDelete(children)}
          onKeyUp={() => itemDelete(children)}
          style={{ display: deleteShowValue }}
        >
          <img
            src={cancelIcon}
            alt="cancel-but-dnd-icon"
            style={{ width: 11, height: 11 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ParserItem;
