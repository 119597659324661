import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import AddSourceModal from 'components/AddSourceModal';
import Square from '../Square';
import Search from '../Search';
import styles from './styles.module.scss';

const DroppableParserBlock = (props) => {
  const {
    cols,
    title,
    clickStr,
    side,
    moveAllSources,
    onSearchChange,
    clearClick,
    searchValue,
    dropFunc,
    hover,
    children,
    refreshButton = false,
    refreshButEnable = false,
    blockRef = null,
    useHandleChildScroll = false,
    currentParser,
  } = props;

  const [addSourceShow, setAddSourceShow] = useState(false);

  const cancelAddAction = () => {
    setAddSourceShow(false);
  };

  const ref = blockRef ? { ref: blockRef } : {};

  return (
    <div className={styles.widgetMainContainer} {...ref}>
      <div className={styles.containerTitle}>
        <div className={styles.titleStyle}>
          {title}
        </div>
        {side === 'left' ? (
          <Button
            additionalStyles={styles.addSourceButton}
            additionalTextStyles={styles.buttonText}
            onClick={() => setAddSourceShow(true)}
          >
            <FormattedMessage id="add.source.btn" />
          </Button>
        ) : null}
        <div
          role="button"
          tabIndex="0"
          className={styles.clickableStr}
          onClick={() => moveAllSources(side)}
          onKeyUp={() => moveAllSources(side)}
        >
          {clickStr}
        </div>
      </div>
      <div className={styles.borderContainer}>
        <div className={styles.itemsContainerWrapper}>
          <Search
            side={side}
            onSearchChange={onSearchChange}
            clearClick={clearClick}
            searchValue={searchValue}
          />
          <Square
            hover={hover}
            dropFunc={dropFunc}
            cols={cols}
            useHandleChildScroll={useHandleChildScroll}
          >
            {children}
          </Square>

          {refreshButton
          && (
          <Button
            disabled={!refreshButEnable}
            additionalStyles={!refreshButEnable
              ? styles.refreshButStyleDisabled
              : styles.refreshButStyle}
            onClick={refreshButton}
          >
            Apply
          </Button>
          )}

        </div>
      </div>

      {side === 'left' ? (
        <AddSourceModal
          waiting={false}
          error={null}
          cancelHandler={cancelAddAction}
          saveHandler={() => {}}
          show={addSourceShow}
          selectedParser={currentParser}
        />
      ) : null}
    </div>
  );
};

export default DroppableParserBlock;
