import React, { useRef, useState } from 'react';
import styles from './style.module.scss';
import { defaultPostImageUrl } from '../../consts/index';

const ImgWithLoader = ({ imgUrl, imgClass = {}, parserName = 'default' }) => {
  const [imgLoading, setImgloading] = useState(true);
  const postImg = useRef(null);
  const defaultParserImageUrl = `/icons/default-imgs/default-image-${parserName}.svg`;

  const errorLoadImgHandler = () => {
    postImg.current.src = postImg.current.src.includes(defaultParserImageUrl)
      ? defaultPostImageUrl
      : defaultParserImageUrl;
  };

  return (
    <div className={imgLoading ? styles.loader : styles.imgWrapper}>
      <img
        className={imgLoading ? styles.invisible : imgClass}
        ref={postImg}
        src={imgUrl || defaultParserImageUrl}
        alt="post_img"
        onError={errorLoadImgHandler}
        onLoad={() => { setImgloading(false); }}
      />
    </div>
  );
};

export default ImgWithLoader;
