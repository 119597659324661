import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import useRequest from 'hooks/useRequest';
import styles from './styles.module.scss';

const SectionsSorting = (props) => {
  const { parserInfo, handler } = props;
  const initSection = parserInfo && parserInfo.options && parserInfo.options.sections && parserInfo.options.sections[0] ? parserInfo.options.sections[0] : 'All sections';
  const [chosenSection, setChosenSection] = useState(initSection);

  const [sections, setSections] = useState([]);

  const sectionClickHandler = (section) => {
    setChosenSection(section);
    handler(section);
  };

  const { id: parserId } = parserInfo;

  const {
    data, doRequest,
  } = useRequest(2);

  useEffect(() => {
    doRequest(`${process.env.REACT_APP_API_BASE_URL}/filters/${parserId}?filterTime=7d`);
  }, [parserId, doRequest]);

  useEffect(() => {
    if (!data || !data[0] || !data[0].values) return;
    const { values } = data[0];
    values.unshift('All sections');
    setSections(values);
  }, [data]);

  const sectionItems = sections.map((section) => {
    const secItem = section;
    return (
      <div
        role="button"
        tabIndex="0"
        onKeyDown={() => { sectionClickHandler(section); }}
        onClick={() => { sectionClickHandler(section); }}
        key={`section_key_${section}${Math.random().toFixed(8)}`}
        className={classNames(styles.sectionItem,
          { [styles.chosenSection]: secItem === chosenSection })}
      >
        {secItem}
      </div>
    );
  });

  return (
    <div className={styles.sPFMiddleBlock}>
      <div className={styles.divider} />
      <div className={styles.sPFTitle}>
        Sections:
      </div>
      {sectionItems}
    </div>
  );
};

export default SectionsSorting;
