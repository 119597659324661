export const SET_TIMEFILTER = 'SET_TIMEFILTER';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_TRANSIT_SORTING_OPTIONS = 'SET_TRANSIT_SORTING_OPTIONS';
export const SET_TRANSIT_TIMEFILTER = 'SET_TRANSIT_TIMEFILTER';
export const SET_CURRENT_PARSER_INFO = 'SET_CURRENT_PARSER_INFO';
export const CLEAR_CURRENT_PARSER_INFO = 'CLEAR_CURRENT_PARSER_INFO';

const initialState = {
  pageSize: '',
  search: '',
  timeFilter: { type: 'hour', value: 6 },
  transitSortingOptions: null,
  transitTimeFilter: null,
  currentParserInfo: null,
};

export const clearCurrentParserInfo = () => ({
  type: CLEAR_CURRENT_PARSER_INFO,
});

export const setCurrentParserInfo = (data) => ({
  type: SET_CURRENT_PARSER_INFO,
  data,
});

export const setTransitTimeFilter = (data) => ({
  type: SET_TRANSIT_TIMEFILTER,
  data,
});

export const setTransitSortingOptions = (data) => ({
  type: SET_TRANSIT_SORTING_OPTIONS,
  data,
});

export const setSearch = (data) => ({
  type: SET_SEARCH,
  data,
});

export const setPageSize = (data) => ({
  type: SET_PAGE_SIZE,
  data,
});

export const setTimeFilter = (data) => ({
  type: SET_TIMEFILTER,
  data,
});

const page = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return { ...state, search: action.data };
    case SET_TIMEFILTER:
      return { ...state, timeFilter: action.data };

    case SET_PAGE_SIZE:
      return { ...state, pageSize: action.data };

    case SET_TRANSIT_SORTING_OPTIONS:
      return { ...state, transitSortingOptions: action.data };

    case SET_TRANSIT_TIMEFILTER:
      return { ...state, transitTimeFilter: action.data };

    case SET_CURRENT_PARSER_INFO:
      return { ...state, currentParserInfo: action.data };

    case CLEAR_CURRENT_PARSER_INFO:
      return { ...state, currentParserInfo: null };

    default: return state;
  }
};

export default page;
