import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { getDateDiff } from '../../../helpers';
import UserAvatar from '../UserAvatar';

const CommentItem = (props) => {
  const {
    user,
    date,
    body,
  } = props;

  const utcDate = getDateDiff(date);
  const reference = useRef();
  useEffect(() => {
    if (reference.current) {
      reference.current.scrollIntoView();
    }
  }, []);
  return (
    <div className={styles.commentItem} ref={reference}>
      <div className={styles.commentItemHeader}>
        <UserAvatar user={user} />
        <div className={styles.commentItemDate}>{utcDate}</div>
      </div>
      <div className={styles.commentItemBody}>{body}</div>
    </div>
  );
};

export default CommentItem;
