import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTemplate, deleteParserTemplate, setCurrentTemplateIndex } from 'store/reducers/templates';
import { readTemplate } from 'store/reducers/parsers';
import RequestsApi from 'services/requestsApi';
import Button from 'components/Button';
import SaveTemplateComponent from 'components/SaveTemplateComponent';
import DeleteTemplateComponent from 'components/DeleteTemplateComponent';
import { createDefaultFiltersTemplate, templateDateConvert } from 'helpers';
import { setTimeFilter } from 'store/reducers/page';
import storageService from 'services/StorageService';
import FilterTemplatesItem from './FilterTemplatesItem';
import styles from './styles.module.scss';

const TemplatesComponent = () => {
  const templates = useSelector((state) => state.templates);
  const allSources = useSelector((state) => state.parsers.allSources);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [newTemplateWindowShow, setNewTemplateWindowShow] = useState(false);
  const [showDeleteTemplateWindow, setShowDeleteTemplateWindow] = useState(false);
  const [deletingTemplateName, setDeletingTemplateName] = useState('');
  const [error, setError] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const saveDisabled = (templates.currentTemplate
    && templates.currentTemplate.name !== 'none')
    || !allSources.find((v) => v.isMarked);
  const isEmptyName = !inputValue.trim().length;

  useEffect(() => {
    setInputValue('');
  }, [allSources]);

  const deleteTemplateHandler = (templateName) => {
    if (templateName) {
      let templateId;

      templates.allTemplates.forEach((template) => {
        if (template.name === templateName) {
          templateId = template.id;
        }
      });

      if (templateId) {
        (async () => {
          setWaiting(true);
          try {
            const response = await RequestsApi.deleteTemplate(templateId);

            if (response) {
              if (templates.allTemplates.length === 1) {
                const defTemplate = createDefaultFiltersTemplate();
                dispatch(addNewTemplate(defTemplate));
              }
              dispatch(deleteParserTemplate(templateId));
              setShowDeleteTemplateWindow(false);
            }
          } catch (err) {
            if (err.response) {
              setError(err.response.data.message);
            } else {
              setError(err.message);
            }
          }
          setWaiting(false);
        })();
      }
    }
  };

  const saveTemplateHandler = (value) => {
    if (value) {
      setWaiting(true);

      const newSources = JSON.parse(JSON.stringify(allSources));
      const markedSources = newSources.filter((v) => v.isMarked);
      const template = createDefaultFiltersTemplate(markedSources);
      template.name = value;

      (async () => {
        try {
          const answer = await RequestsApi.saveTemplate(template);
          template.id = answer.data.id;
          dispatch(addNewTemplate(template));
          setNewTemplateWindowShow(false);
        } catch (err) {
          if (err.response) {
            setError(err.response.data.message);
          } else {
            setError(err.message);
          }
        }

        setWaiting(false);
        setInputValue('');
      })();
    }
  };

  const cancelTemplateAction = (val) => {
    if (val === 'delWin') {
      if (waiting) {
        return;
      }
      setError('');
      setShowDeleteTemplateWindow(false);
    } else {
      if (waiting) {
        return;
      }
      setError('');
      setNewTemplateWindowShow(false);
    }
    setInputValue('');
  };

  const clkItemToDeleteHandler = (e, itemIndex) => {
    setDeletingTemplateName(templates.allTemplates[itemIndex].name);
    setShow(!show);
    setShowDeleteTemplateWindow(true);
    e.stopPropagation();
  };

  const itemChoose = (index) => {
    dispatch(setTimeFilter(templateDateConvert(templates.allTemplates[index].global.period)));
    dispatch(setCurrentTemplateIndex(index));
    dispatch(readTemplate(templates.allTemplates[index]));
    storageService.add('currentTemplateName', templates.allTemplates[index].name);
    setShow(false);
  };

  const renderedItems = [];

  if (templates.currentTemplateIndex >= 0) {
    templates.allTemplates.forEach((v, i) => (
      renderedItems.push(<FilterTemplatesItem
        key={`key_${v}${v.name}`}
        name={v.name}
        index={i}
        clkHandler={itemChoose}
        clkDeleteHandler={clkItemToDeleteHandler}
      />)
    ));
  }

  const reversedOrderItems = [...renderedItems];
  reversedOrderItems.reverse();

  const handleChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  return (
    <div className={styles.tempCompContainer}>
      <div className={styles.title}>Templates</div>
      <div className={styles.navLine}>
        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="name"
            spellCheck={false}
            value={inputValue}
            onChange={handleChange}
            disabled={saveDisabled}
          />
        </div>
        <Button
          disabled={saveDisabled || isEmptyName}
          additionalStyles={saveDisabled || isEmptyName
            ? styles.saveButtonDisabled : styles.saveButton}
          onClick={() => setNewTemplateWindowShow(true)}
        >
          Save new
        </Button>
      </div>
      <div className={styles.itemsContWrapper}>
        <div className={styles.itemsCont}>
          <div className={styles.tfcRect}>
            {reversedOrderItems}
          </div>
        </div>
      </div>

      <SaveTemplateComponent
        waiting={waiting}
        error={error}
        cancelHandler={cancelTemplateAction}
        saveHandler={saveTemplateHandler}
        show={newTemplateWindowShow}
        inputValue={inputValue}
      />

      <DeleteTemplateComponent
        waiting={waiting}
        error={error}
        cancelHandler={cancelTemplateAction}
        show={showDeleteTemplateWindow}
        templateName={deletingTemplateName}
        deleteHandler={deleteTemplateHandler}
      />
    </div>
  );
};

export default React.memo(TemplatesComponent);
