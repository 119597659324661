import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setSearch } from 'store/reducers/page';
import searchIcon from 'assets/icons/search-icon.svg';
import cancelIcon from 'assets/icons/cancel-icon.svg';
import styles from './styles.module.scss';

const SearchComponent = (props) => {
  const { hideable = true, expandHandler } = props;
  const [show, setShow] = useState(!hideable);
  const [findStr, setFindStr] = useState('');
  const dispatch = useDispatch();
  const el = useRef(null);

  const hideHandler = () => {
    dispatch(setSearch(''));
    setFindStr('');
    if (hideable) {
      setShow(false);
      expandHandler(true);
    }
  };

  const searchHandler = () => {
    if (hideable && !show) {
      setShow(true);
      expandHandler(false);
      return;
    }
    dispatch(setSearch(findStr));
  };

  const handleChange = (event) => {
    setFindStr(event.target.value);
  };

  const keyHandle = (e) => {
    if (e.key === 'Enter') searchHandler();
    if (e.key === 'Escape') hideHandler();
  };

  const widgetClickHandler = () => {
    if (hideable && !show) {
      setShow(true);
    }
    el.current.focus();
  };

  return (
    <div
      role="button"
      tabIndex="0"
      className={classNames(styles.searchComponentCont, { [styles.outline]: hideable && show })}
      onKeyDown={keyHandle}
      onClick={widgetClickHandler}
    >
      <div
        className={styles.searchComponentFindBut}
        role="button"
        tabIndex="0"
        onClick={searchHandler}
        onKeyDown={searchHandler}
      >
        <img src={searchIcon} alt="search_Button" />
      </div>

      <div className={classNames(styles.searchComponentHiddenPart, { [styles.hidden]: !show })}>

        <div className={styles.searchComponentInputCont}>
          <input ref={el} className={styles.searchComponentInput} type="text" spellCheck="false" value={findStr} onChange={handleChange} />
        </div>

        <div
          className={
            classNames(styles.searchComponentCancelBut,
              { [styles.hideCross]: !hideable && !findStr })
          }
          onClick={hideHandler}
          onKeyDown={hideHandler}
          role="button"
          tabIndex="0"
        >
          <img src={cancelIcon} alt="cancel_Button" />
        </div>
      </div>

    </div>
  );
};

export default SearchComponent;
