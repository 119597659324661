import { combineReducers } from 'redux';
import parsers from 'store/reducers/parsers';
import user from './reducers/user';
import page from './reducers/page';
import templates from './reducers/templates';
import modal from './reducers/modal';

const mainReducer = combineReducers({
  user,
  parsers,
  page,
  templates,
  modal,
});

export default mainReducer;
