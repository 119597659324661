import React from 'react';
import classNames from 'classnames';
import skeletonString1 from 'assets/imgs/skeleton-empty-double-string1.svg';
import skeletonImgMd from 'assets/imgs/skeleton-empty-img-md.svg';
import styles from './styles.module.scss';

const MdBlock = ({ size }) => {
  let styleStr;
  let count;

  switch (size) {
    case 'DESKTOP':
      styleStr = 'col-4';
      count = 4;
      break;
    default:
      styleStr = 'col-8';
      count = 12;
      break;
  }

  const content = [];
  for (let i = 0; i < count; i += 1) {
    content.push(
      <div
        key={`md-inner-block-${Math.random().toFixed(5)}`}
        className={classNames(styles.sBlockMd, { [styles.sBlockMdWide]: count === 12 })}
      >
        <img alt="loading" src={skeletonImgMd} className={styles.sBlockFrameMd} />
        <img alt="loading" src={skeletonString1} className={styles.sBlockStringsMd} />
      </div>,
    );
  }

  return (
    <div className={classNames(styles.sBCont, `${styleStr}`)}>
      {content}
    </div>
  );
};

export default MdBlock;
