import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import SearchComponent from 'components/SearchComponent';
import filterButtonIcon from 'assets/icons/filter-button-icon.svg';
import storageService from 'services/StorageService';
import Button from 'components/Button';
import TimeFilterComponent from 'components/FiltersBar/TimeFilterComponent';
import styles from './styles.module.scss';

const Header = (props) => {
  const { history, showHandler } = props;
  const [setFtcShow] = useState(true);
  const signOut = () => {
    storageService.clear();
    history.push('/login');
  };

  const hideFtc = (val) => {
    setFtcShow(val);
  };

  const styleForTemplates = window.location.pathname === '/'
    ? styles.templatesSwitcher
    : styles.templatesSwitcherHide;

  return (
    <div className={classNames(styles.navPanelCont, 'row')}>
      <div className={classNames(styles.navPanel, 'col-12 col-xl-10 row')}>
        <div className={classNames(styles.leftBlock, 'col-12 col-md-2 mb-2 mb-md-0')}>
          <Link to="/" className={styles.leftBlockLogo}>Trendsdash.io</Link>
          <Link className={classNames(styles.logoutLink, 'd-md-none')} to="/login" onClick={signOut}>
            <FormattedMessage id="header.logout" />
          </Link>
        </div>

        <div className={classNames(styles.rightBlock, 'col-md-10 col-12')}>
          <div className={classNames(styles.filtersStyle, styleForTemplates)}>
            <Button
              onClick={showHandler}
              icon={filterButtonIcon}
              additionalStyles={styles.filterBarButton}
            >
              <FormattedMessage id="header.parsersFilter" />
            </Button>
            <div className={styles.timeFilterComponentStyle}>
              <TimeFilterComponent />
            </div>
          </div>

          <div className="d-none d-md-block mr-2">
            <SearchComponent expandHandler={hideFtc} />
          </div>
          <Link className={classNames(styles.logoutLink, 'd-none d-md-block')} to="/login" onClick={signOut}>
            <FormattedMessage id="header.logout" />
          </Link>
        </div>

      </div>
      <div className={classNames(styles.divider, 'd-md-block d-none')} />
    </div>
  );
};

export default withRouter(React.memo(Header));
