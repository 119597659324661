import React, { useEffect, useState } from 'react';

const Text = ({ content, additionalStyles = '' }) => {
  const [txt, setTxt] = useState('');

  useEffect(() => {
    const div = document.createElement('div');
    div.innerHTML = content;
    div.innerHTML = div.innerText;
    setTxt(div.innerText);
  }, [content]);

  return (
    <div className={additionalStyles}>
      {txt}
    </div>
  );
};

export default Text;
