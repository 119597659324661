import localStorageStorageSource from './storageSources/localStorageStorageSource';
import defaultStorageSource from './storageSources/defaultStorageSource';
import cookiesStorageSource from './storageSources/cookiesStorageSource';

class StorageService {
  constructor() {
    this.storageSourceName = '';
    this.buildStorageSource();
  }

  buildStorageSource() {
    try {
      if (window.localStorage) {
        this.storageSource = localStorageStorageSource;
        this.storageSourceName = this.storageSource.name;
        return;
      }

      document.cookie = 'test45245=testString';
      if (document.cookie) {
        this.storageSource = cookiesStorageSource;
        this.storageSourceName = this.storageSource.name;
        return;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    this.storageSource = defaultStorageSource;
    this.storageSourceName = this.storageSource.name;
  }

  get(vname) {
    return this.storageSource.get(vname);
  }

  add(vname, value) {
    this.storageSource.add(vname, value);
  }

  delete(vname) {
    this.storageSource.delete(vname);
  }

  clear() {
    this.storageSource.clear();
  }
}

export default new StorageService();
