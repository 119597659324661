import React from 'react';
import { uuid } from '../helpers';
import NotificationService from './NotificationService';
import CommentNotification from '../components/CommentsComponent/CommentNotification';
import store from '../store/store';

class WebSocketApi {
  constructor() {
    this.listeners = new Map();
    this.roomSubscription = new Map();
    this.counterSubscription = new Map();
  }

  init(websocket) {
    this.ws = websocket;
    this.addMessageListener();
    this.sendPing();
  }

  isInit() {
    return !!this.ws;
  }

  once(rid, cb) {
    this.listeners.set(rid, cb);
  }

  async sendComment(commentData) {
    if (!this.ws) {
      return null;
    }
    return this.send('createComment', {
      postId: commentData.id,
      body: commentData.message,
    });
  }

  send(action, sendData) {
    return new Promise((resolve, reject) => {
      const rid = uuid();
      this.once(rid, ({ error, data }) => {
        if (error) {
          return reject(data);
        }
        return resolve(data);
      });

      this.ws.send(JSON.stringify({
        ...sendData,
        action,
        rid,
      }));
    });
  }

  sendPing() {
    setTimeout(async () => {
      if (this.ws.instance && this.ws.instance.readyState === 1) {
        const res = await this.send('ping', { status: 1 });
        if (res) {
          this.sendPing();
        }
      } else {
        this.sendPing();
      }
    }, 30 * 1000);
  }

  // eslint-disable-next-line class-methods-use-this
  filteredParsers(data) {
    try {
      const postCrawlerId = data.post.crawlerId;
      const { currentParserInfo } = store.getState().page;
      const { currentTemplate } = store.getState().templates;

      if (currentParserInfo && currentParserInfo.id) {
        return currentParserInfo.id === postCrawlerId;
      }

      if (currentTemplate && currentTemplate.crawlers) {
        const openedCrawlers = currentTemplate.crawlers;
        const openedCrawlerIds = openedCrawlers.map((crawler) => crawler.crawlerId);
        return openedCrawlerIds.includes(postCrawlerId);
      }

      return false;
    } catch (error) {
      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  showCommentsNotifications(data) {
    if (!this.filteredParsers(data.data)) {
      return;
    }

    NotificationService.addNotification(null,
      null, {
        content: <CommentNotification data={data.data} />,
        showIcon: true,
      });
  }

  addMessageListener() {
    this.ws.addEventListener('message', (event) => {
      const data = JSON.parse(event.detail);
      if (data.action === 'added-comment') {
        this.showCommentsNotifications(data);
        const postId = data.data.post.id;
        const isSubscribeCb = this.roomSubscription.get(postId);
        if (isSubscribeCb) {
          isSubscribeCb(data.data);
        }
        this.resolveCommentsCounterCb(data);
      }

      if (data.action === 'response' && data.data.post) {
        this.resolveCommentsCounterCb(data);
      }

      const cb = this.listeners.get(data.rid);
      if (cb) {
        this.listeners.delete(data.rid);
        cb(data);
      }
    });
  }

  subscribeToComments(postID, cb) {
    this.roomSubscription.set(postID, cb);
  }

  subscribeToCommentsCounter(postID, cb) {
    this.counterSubscription.set(postID, cb);
  }

  unSubscribeToComments(postID) {
    this.roomSubscription.delete(postID);
  }

  unSubscribeToCommentsCounter(postID) {
    this.counterSubscription.delete(postID);
  }

  resolveCommentsCounterCb(data) {
    const postId = data.data.post.id;
    const isSubscribeCounterCb = this.counterSubscription.get(postId);
    if (isSubscribeCounterCb) {
      isSubscribeCounterCb(data.data);
    }
  }
}

export default new WebSocketApi();
