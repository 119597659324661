import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import BadgeItem from './BadgeItem';
import { getDateDiff, getNormalizeDate, likeCounterRender } from '../../helpers';
import parserConfig from './parsers-config';

const BadgeContainer = (props) => {
  const { data, parserName, small = false } = props;
  const junkeeParserName = 'junkeeParser';
  const lastDateCheckingJunkee = new Date('Mon, 08 Feb 2021 10:28:29 GMT');

  const parserValue = parserConfig[parserName];

  let content = [];

  if (parserValue && parserValue.badges && parserValue.badges.length > 0) {
    content = parserValue.badges.map((param) => {
      const incomData = param.getProp(data);

      return (
        <BadgeItem
          key={`${parserName}_badge_${Math.random().toFixed(8)}`}
          tooltipText={param.tooltipText}
          iconType={param.iconType}
          value={likeCounterRender(parseFloat(incomData))}
          small={false}
        />
      );
    });
  }

  const shiftDate = (date) => {
    const shiftedTimeStamp = date.getTime() - 11 * 3600 * 1000;
    const newDate = new Date(shiftedTimeStamp).toUTCString();
    return newDate;
  };

  const correctDate = (dateString) => {
    if (parserName === junkeeParserName) {
      const createdDate = new Date(data.dateCreated);
      const publishDate = new Date(dateString);

      if (createdDate - lastDateCheckingJunkee < 0 || new Date() - publishDate < 0) {
        return shiftDate(publishDate);
      }
    }
    return dateString;
  };

  const utcDate = (parserValue && parserValue.getPublishDate)
    ? getDateDiff(correctDate(`${parserValue.getPublishDate(data)}`))
    : '';

  const author = parserValue && parserValue.getAuthorName ? parserValue.getAuthorName(data) : '';
  const publishDate = utcDate ? `${utcDate}` : '';
  const authorLink = parserValue && parserValue.getAuthorLink ? parserValue.getAuthorLink(data) : '';
  const sourceHost = parserValue && parserValue.getHostName ? parserValue.getHostName(data) : '';
  const sourceOnlyHost = sourceHost ? (new URL(sourceHost).host) : '';

  const { dateCreated } = data;

  return (
    <div>
      <div className={classNames(
        styles.authorAndDateContainer,
        { [styles.nameDateShow]: !small },
      )}
      >
        <div className={classNames(styles.authorCont)}>
          {author}
          <div className={styles.simpleTooltip}>
            <div className={styles.simpleTooltipText}>
              Author name
            </div>
          </div>
        </div>
        <div className={classNames(styles.dateCont)}>
          {publishDate}
          <div className={styles.simpleTooltip}>
            <div className={styles.simpleTooltipText}>
              Publish date
            </div>
          </div>
        </div>
      </div>
      <div className={
        classNames(styles.authorLinkStyle, { [styles.authorLinkStyleShow]: authorLink })
      }
      >
        <a href={authorLink} target="_blank" rel="noreferrer">(publisher link)</a>
      </div>

      <div className={
        classNames(styles.sourceHostStyle, { [styles.sourceHostStyleShow]: sourceHost })
      }
      >
        <a href={sourceHost} target="_blank" rel="noreferrer">
          {sourceOnlyHost}
        </a>
      </div>

      <div className={
        classNames(styles.badgeContainer, styles.badgeContainerShow)
      }
      >
        {content}
        <div className={classNames(
          styles.authorAndDateContainer,
          styles.smallAuthorAndDateContainer,
          { [styles.nameDateShow]: (small && (author || publishDate)) },
        )}
        >
          <div className={classNames(styles.authorCont)}>
            {author}
            <div className={classNames(styles.simpleTooltip, styles.lastTooltip)}>
              <div className={styles.simpleTooltipText}>
                Author name
              </div>
            </div>
          </div>
          <div className={classNames(styles.dateCont)}>
            {publishDate}
            <div className={classNames(styles.simpleTooltip, styles.lastTooltip)}>
              <div className={styles.simpleTooltipText}>
                Publish date
              </div>
            </div>
          </div>
        </div>
        <div className={styles.infoCont}>
          <BadgeItem
            key={`${parserName}_badge_${Math.random().toFixed(8)}`}
            tooltipText={`updated ${getNormalizeDate(dateCreated)}`}
            iconType="creative-date"
            infoType
            small
          />
        </div>

      </div>
    </div>

  );
};

export default BadgeContainer;
