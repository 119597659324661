const parserConfig = {
  lessonsInMemeCultureParser: {
    getPublishDate(data) {
      return new Date(data.datePublished).toUTCString();
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'likes',
        tooltipText: 'Likes',
        getProp(data) {
          return data.statistic.likeCount;
        },
      },
      {
        iconType: 'views',
        tooltipText: 'Views',
        getProp(data) {
          return data.statistic.viewCount;
        },
      },
    ],
  },
  redditLiveStreamFail: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],

  },
  redditGamingNews: {
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.commentCount;
          }
          return null;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.upvote_ratio;
          }
          return null;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.score;
          }
          return null;
        },
      },
    ],

  },
  redditMemeTemplatesOfficial: {
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.commentCount;
          }
          return null;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.upvote_ratio;
          }
          return null;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.score;
          }
          return null;
        },
      },
    ],

  },
  redditMemeEconomy: {
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.commentCount;
          }
          return null;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.upvote_ratio;
          }
          return null;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.score;
          }
          return null;
        },
      },
    ],

  },
  redditMemes: {
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.commentCount;
          }
          return null;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.upvote_ratio;
          }
          return null;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.score;
          }
          return null;
        },
      },
    ],

  },
  redditDankMemes: {
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.commentCount;
          }
          return null;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.upvote_ratio;
          }
          return null;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.score;
          }
          return null;
        },
      },
    ],

  },
  redditPcMasterRace: {
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.commentCount;
          }
          return null;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.upvote_ratio;
          }
          return null;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.score;
          }
          return null;
        },
      },
    ],

  },
  redditGaming: {
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.commentCount;
          }
          return null;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.upvote_ratio;
          }
          return null;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic) {
            return data.statistic.reddit.score;
          }
          return null;
        },
      },
    ],

  },
  facebookMemes: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      return data.author;
    },
    getAuthorLink(data) {
      return data.statistic.facebook.account.url;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'likes-facebook',
        tooltipText: 'Reactions',
        getProp(data) {
          let sum = parseInt(data.statistic.facebook.statistics.actual.likeCount, 10);
          sum += parseInt(data.statistic.facebook.statistics.actual.loveCount, 10);
          sum += parseInt(data.statistic.facebook.statistics.actual.wowCount, 10);
          sum += parseInt(data.statistic.facebook.statistics.actual.hahaCount, 10);
          sum += parseInt(data.statistic.facebook.statistics.actual.sadCount, 10);
          sum += parseInt(data.statistic.facebook.statistics.actual.angryCount, 10);
          sum += parseInt(data.statistic.facebook.statistics.actual.thankfulCount, 10);
          sum += parseInt(data.statistic.facebook.statistics.actual.careCount, 10);
          return sum;
        },
      },
      {
        iconType: 'retweets',
        tooltipText: 'Shares',
        getProp(data) {
          return data.statistic.shareCount;
        },
      },
    ],
  },
  dailyDot: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      const { statistic: { dailyDot: { _embedded: embedData } } } = data;
      return embedData.author[0].name;
    },
  },
  googleNews: {
    getHostName(data) {
      const url = new URL(data.link);
      return `${url.protocol}//${url.host}`;
    },
    getPublishDate(data) {
      return data.datePublished;
    },
  },
  googleAlertViralVideo: {
    getHostName(data) {
      const url = new URL(data.link);
      return `${url.protocol}//${url.host}`;
    },
    getPublishDate(data) {
      return data.datePublished;
    },
  },
  googleAlertMemes: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getHostName(data) {
      const url = new URL(data.link);
      return `${url.protocol}//${url.host}`;
    },
  },
  googleTrends: {
    getHostName(data) {
      const url = new URL(data.link);
      return `${url.protocol}//${url.host}`;
    },
    //   getPublishDate(data) {
    //     return data.datePublished;
    //   },
  },
  junkeeParser: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      return data.author;
    },
  },
  melMagazineParser: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      return data.author;
    },
  },
  twitterTrendsUSA: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'retweets',
        tooltipText: 'Retweets',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic.totalShareCount) {
            return data.statistic.totalShareCount;
          }
          return data.statistic.shareCount;
        },
      },
      {
        iconType: 'likes',
        tooltipText: 'Likes',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic.totalLikeCount) {
            return data.statistic.totalLikeCount;
          }
          return data.statistic.likeCount;
        },
      },
    ],
  },
  twitterTrendsWorldwide: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'retweets',
        tooltipText: 'Retweets',
        getProp(data) {
          // eslint-disable-next-line react/destructuring-assignment
          if (data.statistic.totalShareCount) {
            return data.statistic.totalShareCount;
          }
          return data.statistic.shareCount;
        },
      },
      {
        iconType: 'likes',
        tooltipText: 'Likes',
        getProp(data) {
          if (data.statistic.totalLikeCount) {
            return data.statistic.totalLikeCount;
          }
          return data.statistic.likeCount;
        },
      },
    ],
  },
  stayHippMemes: {
    getPublishDate(data) {
      return data.datePublished;
    },
  },
  boredPanda: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'views',
        tooltipText: 'Views',
        getProp(data) {
          return data.statistic.boredPanda.views;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Points',
        getProp(data) {
          return data.statistic.boredPanda.points;
        },
      },
      {
        iconType: 'retweets',
        tooltipText: 'Replies',
        getProp(data) {
          return data.statistic.boredPanda.replies;
        },
      },

    ],
  },
  tikTokMusicUsa: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          if (data.statistic.totalCommentCount) {
            return data.statistic.totalCommentCount;
          }
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'views',
        tooltipText: 'Play count',
        getProp(data) {
          if (data.statistic.totalPlayCount) {
            return data.statistic.totalPlayCount;
          }
          return data.statistic.playCount;
        },
      },
      {
        iconType: 'likes',
        tooltipText: 'Likes',
        getProp(data) {
          if (data.statistic.totalLikeCount) {
            return data.statistic.totalLikeCount;
          }
          return data.statistic.likeCount;
        },
      },
    ],
  },
  tikTokChallengesUsa: {
    getPublishDate(data) {
      return data.datePublished;
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          if (data.statistic.totalCommentCount) {
            return data.statistic.totalCommentCount;
          }
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'views',
        tooltipText: 'Play count',
        getProp(data) {
          if (data.statistic.totalPlayCount) {
            return data.statistic.totalPlayCount;
          }
          return data.statistic.playCount;
        },
      },
      {
        iconType: 'likes',
        tooltipText: 'Likes',
        getProp(data) {
          if (data.statistic.totalLikeCount) {
            return data.statistic.totalLikeCount;
          }
          return data.statistic.likeCount;
        },
      },
    ],
  },
  redditOutOfTheLoop: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],

  },
  knowYourMemeEmptySearch: {
    badges: [
      {
        iconType: 'views',
        tooltipText: 'Total',
        getProp(data) {
          return data.statistic.knowYourMemeEmptySearch.total;
        },
      },
    ],

  },

  chartsBeatCheezburger: {
    badges: [
      {
        iconType: 'views',
        tooltipText: 'Read',
        getProp(data) {
          return data.statistic.chartBeat.read;
        },
      },
      {
        iconType: 'retweets',
        tooltipText: 'Links',
        getProp(data) {
          return data.statistic.chartBeat.links;
        },
      },
      {
        iconType: 'people',
        tooltipText: 'People',
        getProp(data) {
          return data.statistic.chartBeat.people;
        },
      },
    ],
  },

  chartsBeatKnowyourmeme: {
    badges: [
      {
        iconType: 'views',
        tooltipText: 'Read',
        getProp(data) {
          return data.statistic.chartBeat.read;
        },
      },
      {
        iconType: 'retweets',
        tooltipText: 'Links',
        getProp(data) {
          return data.statistic.chartBeat.links;
        },
      },
      {
        iconType: 'people',
        tooltipText: 'People',
        getProp(data) {
          return data.statistic.chartBeat.people;
        },
      },
    ],
  },

  chartsBeatEbaumsWorld: {
    badges: [
      {
        iconType: 'views',
        tooltipText: 'Read',
        getProp(data) {
          return data.statistic.chartBeat.read;
        },
      },
      {
        iconType: 'retweets',
        tooltipText: 'Links',
        getProp(data) {
          return data.statistic.chartBeat.links;
        },
      },
      {
        iconType: 'people',
        tooltipText: 'People',
        getProp(data) {
          return data.statistic.chartBeat.people;
        },
      },
    ],
  },

  chartsBeatCracked: {
    badges: [
      {
        iconType: 'views',
        tooltipText: 'Read',
        getProp(data) {
          return data.statistic.chartBeat.read;
        },
      },
      {
        iconType: 'retweets',
        tooltipText: 'Links',
        getProp(data) {
          return data.statistic.chartBeat.links;
        },
      },
      {
        iconType: 'people',
        tooltipText: 'People',
        getProp(data) {
          return data.statistic.chartBeat.people;
        },
      },
    ],
  },
  redditTifu: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],
  },
  redditTumblr: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],
  },
  redditTalesFromTechSupport: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],
  },
  redditProRevenge: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],
  },
  redditPettyRevenge: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],
  },
  redditMaliciousCompliance: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],
  },
  redditAskReddit: {
    getPublishDate(data) {
      return new Date(data.statistic.reddit.created_utc * 1000).toUTCString();
    },
    getAuthorName(data) {
      return data.author;
    },
    badges: [
      {
        iconType: 'comments',
        tooltipText: 'Comments',
        getProp(data) {
          return data.statistic.commentCount;
        },
      },
      {
        iconType: 'upvotes',
        tooltipText: 'Upvote Ratio',
        getProp(data) {
          return data.statistic.reddit.upvote_ratio;
        },
      },
      {
        iconType: 'score',
        tooltipText: 'Score',
        getProp(data) {
          return data.statistic.reddit.score;
        },
      },
    ],
  },
};

export default parserConfig;
