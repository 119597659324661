import React from 'react';
import CloseIcon from 'assets/icons/cancel-icon.svg';
import styles from 'components/Fields/Select/Tag/styles.module.scss';

const Tag = (props) => {
  const {
    name = '',
    onRemove = () => {},
  } = props;

  const prevent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onClick={prevent} className={styles.tag}>
      <span className={styles.tagName}>{name}</span>
      <div className={styles.crossIcon} onClick={onRemove}>
        <CloseIcon />
      </div>
    </div>
  );
};

export default Tag;
