import React from 'react';
import classNames from 'classnames';
import { onImageError } from 'helpers';
import styles from './styles.module.scss';

const RenderParserOption = ({ data, chosenItems }) => {
  const { name, value: itemValue, imgSrc } = data;

  return (
    <div className={classNames(
      styles.itemWrapper,
      {
        [styles.checked]: chosenItems.includes(itemValue),
      },
    )}
    >
      <div className={styles.parserIconContainer}>
        <img src={imgSrc} alt="parser-icon" onError={onImageError} />
      </div>
      <div className={styles.nameContainer}>
        {name}
      </div>
    </div>
  );
};

export default RenderParserOption;
