import React from 'react';
import styles from './styles.module.scss';

const UserAvatar = ({ user }) => (
  <div className={styles.notificationAuthor}>
    <div className={styles.notificationAuthorAvatar}>
      <img src={user.picture} alt={user.name} />
    </div>
    {user.name}
  </div>
);

export default UserAvatar;
