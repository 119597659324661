import React, { useState } from 'react';
import classNames from 'classnames';
import cancelIcon from 'assets/icons/cancel-icon-white.svg';
import styles from './styles.module.scss';

const ErrorComp = ({ error }) => {
  const [show, setShow] = useState('true');

  const clickHandler = () => {
    setShow(false);
  };

  return (
    <div className={classNames(styles.container, { [styles.hidden]: !show })}>
      <div
        className={styles.cancelIcon}
        onClick={clickHandler}
        onKeyDown={clickHandler}
        role="button"
        tabIndex="0"
      >
        <img src={cancelIcon} alt="cancelIcon_alt" />
      </div>
      <div className={styles.textCont}>
        {error}
      </div>
    </div>
  );
};

export default ErrorComp;
