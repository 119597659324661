import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Overlay = (props) => {
  const {
    show, children, position = 'top', clickHandler,
  } = props;
  const clkHandler = () => clickHandler(false);

  return (
    <div
      tabIndex="0"
      role="button"
      onKeyDown={clkHandler}
      className={classNames(styles.overlay, { [styles.show]: show }, { [styles.centerPosition]: position === 'center' })}
      onClick={clkHandler}
      aria-label="overlay"
    >
      {children}
    </div>
  );
};
export default Overlay;
