import React from 'react';
import classNames from 'classnames';
import cancelIcon from 'assets/icons/cancel-icon.svg';
import styles from './styles.module.scss';

const FilterTemplatesItem = (props) => {
  const {
    name, index, clkHandler, clkDeleteHandler,
  } = props;

  return (
    <div
      className={styles.timeFilterItemCont}
      role="button"
      tabIndex="0"
      onClick={() => clkHandler(index)}
      onKeyPress={() => clkHandler(index)}
    >
      <div className={styles.leftBlock}>
        {name}
      </div>
      <div
        role="button"
        tabIndex="0"
        onKeyDown={(e) => { clkDeleteHandler(e, index); }}
        onClick={(e) => { clkDeleteHandler(e, index); }}
        className={classNames(styles.rightBlock, { [styles.notDisplayed]: (name === 'none') })}
      >
        <img src={cancelIcon} alt="cancel-img" />
      </div>
    </div>
  );
};

export default FilterTemplatesItem;
