import React from 'react';
import classNames from 'classnames';
import SocialsWidgetBlock from 'components/SocialsWidgetBlock';
import SocialsWidgetBlockBrif from 'components/SocialsWidgetBlockBrif';
import styles from './styles.module.scss';

const SocialsWidgetContainer = (props) => {
  const {
    childs,
    parserWithOutImg = false,
    size = 'lg',
    additionalStyles = {},
    parserName,
    col,
    width,
    sortingPostfix,
    brif,
  } = props;

  const SWB = brif ? SocialsWidgetBlockBrif : SocialsWidgetBlock;
  const content = childs.map((v) => (
    <SWB
      sortingPostfix={sortingPostfix}
      width={width}
      data={v}
      key={`social_widget_block_${parserName}${v.id}`}
      size={size}
      parserName={parserName}
    />
  ));

  return (
    <>
      { parserWithOutImg ? (
        <div className={classNames('row', styles.sWPart, additionalStyles)}>
          {content}
        </div>
      ) : (
        <div className={`col-md-${col}`}>
          <div className={classNames('row', styles.sWPart, additionalStyles)}>
            {content}
          </div>
        </div>
      )}
    </>
  );
};

export default SocialsWidgetContainer;
