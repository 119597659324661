import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { handleHtmlSpecSymbols } from 'helpers';
import Text from 'components/Text';
import { parserNamesWithoutImgs } from 'consts';
import ImgWithLoader from 'components/ImgWithLoader';
import BadgeContainer from 'components/BadgeContainer';
import styles from './styles.module.scss';
import colConfig from './col-sizes-config';

const SocialsWidgetBlockBrif = (props) => {
  const {
    size = 'lg', data = {}, width, parserName, sortingPostfix = '',
  } = props;
  const ind = colConfig[width];

  return (
    <div
      className={classNames(`col-${ind}`, 'mb-4 mb-md-0', styles.sWBouter)}

    >
      <div className={classNames(styles.sWBCont, styles[`sWBCont-${size}`])}>

        <a
          href={data.link}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
          className={styles['sWBFrameCont-md']}
        >
          {parserNamesWithoutImgs.includes(parserName)
            ? (<div className={styles.breakLine} />)
            : (
              <ImgWithLoader
                parserName={parserName}
                imgUrl={handleHtmlSpecSymbols(data.imgUrl)}
                imgClass={styles.sWBFrame}
              />
            )}
        </a>

        <div className={classNames(styles.sWBText, 'searching-label', styles[`sWBText-${size}`])}>

          <Link to={`/parser-data/${parserName}/${encodeURIComponent(data.category.name)}?${sortingPostfix}`}>

            <Text
              content={data.category.name}
              additionalStyles={classNames(styles.trendsStyle,
                { [styles.displayed]: data.category.name })}
            />
          </Link>

          <a
            href={data.link}
            target="_blank"
            rel="noreferrer"
            className={styles.mCont}
          >

            <div className={styles.descriptionPart}>
              <Text
                content={data.title || data.description || 'empty'}
              />
            </div>

            <BadgeContainer data={data} parserName={parserName} />

          </a>

        </div>

      </div>
    </div>
  );
};

export default SocialsWidgetBlockBrif;
