import React from 'react';
import classNames from 'classnames';
import skeletonString3 from 'assets/imgs/skeleton-empty-tripple-string.svg';
import skeletonImgLg from 'assets/imgs/skeleton-empty-img-lg.svg';
import styles from './styles.module.scss';

const LgBlock = ({ size }) => {
  const config = {
    DESKTOP: 4,
    MOBILE: 12,
    WIDE: 2,
  };

  return (
    <div className={classNames(styles.sBCont, `col-${config[size]}`, { [styles.blockMargin]: size === 'MOBILE' })}>
      <div className={styles.sBlockLg}>
        <img alt="loading" src={skeletonImgLg} className={styles.sBlockFrameLg} />
        <img alt="loading" src={skeletonString3} className={styles.sBlockStringsLg} />
      </div>
    </div>
  );
};

export default LgBlock;
