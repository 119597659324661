import React from 'react';
import classNames from 'classnames';
import searchIcon from 'assets/icons/search-dnd-icon.svg';
import cancelIcon from 'assets/icons/cross-dnd-icon.svg';
import styles from './styles.module.scss';

const Search = (props) => {
  const {
    side,
    onSearchChange,
    clearClick,
    searchValue,
    stylesContainer,
  } = props;
  const opacityValue = searchValue ? 'flex' : 'none';

  return (
    <div className={classNames(styles.searchContainer, stylesContainer)}>
      <div className={styles.searchContainerWrapper}>
        <img src={searchIcon} alt="search-dnd-icon" />
        <div className={styles.inputWrapper}>
          <input type="text" value={searchValue} onChange={(event) => onSearchChange(event, side)} />
        </div>

        <div
          role="button"
          tabIndex="0"
          className={styles.cancelButton}
          onClick={() => clearClick(side)}
          onKeyUp={() => clearClick(side)}
          style={{ display: opacityValue }}
        >
          <img style={{ width: 11, height: 11 }} src={cancelIcon} alt="cross-dnd-icon" />
        </div>
      </div>
    </div>
  );
};

export default Search;
