import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector, useDispatch } from 'react-redux';
import { createDefaultFiltersTemplate } from 'helpers';
import classNames from 'classnames';
import { clearSourceFromTemplate, setToNoneTemplate } from 'store/reducers/templates';
import { setModelSources, setSources } from 'store/reducers/parsers';
import arrows from 'assets/icons/arrows-dnd-icon.svg';
import styles from './styles.module.scss';
import DroppableParserBlock from '../DroppableParserBlock';
import ParserItem from '../ParserItem';
import ParserBlock from '../ParserBlock';
import DeleteSourceComponent from '../../DeleteSourceComponent';
import RequestsApi from '../../../services/requestsApi';

const clientBoundRect = (elementRef, containerRef) => {
  const element = elementRef?.current;
  const container = containerRef?.current;
  if (!element || !container) {
    return {
      isVisible: false,
    };
  }
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  return {
    isVisible: elementRect.top >= containerRect.top
        && elementRect.bottom <= containerRect.bottom,
  };
};

const ParserSortWidget = ({ changeParserList, setParsersInfo, setVisibility }) => {
  const dispatch = useDispatch();
  const allSources = useSelector((store) => store.parsers.allSources);
  const allParsers = useSelector((store) => store.parsers.allParsers);
  const currentTemplate = useSelector((store) => store.templates.currentTemplate);
  const [namesMap, setNamesMap] = useState(null);
  const [crawlerIds, setCrawlerIds] = useState(null);
  const [crawlerToParserIds, setCrawlerToParserIds] = useState(null);
  const [crawlerToParser, setCrawlerToParser] = useState(null);
  const [removableList, setRemovableList] = useState(null);
  const [leftList, setLeftList] = useState({
    filtered: [],
    notFiltered: [],
  });
  const [rightList, setRightList] = useState([]);
  const [draggedItem, setDraggedItem] = useState('');
  const [parserValue, setParserValue] = useState('');
  const [leftSearchValue, setLeftSearchValue] = useState('');
  const [rightSearchValue, setRightSearchValue] = useState('');
  const [leftDirect, setLeftDirect] = useState(true);
  const [refreshButEnable, setRefreshButEnable] = useState(true);
  const [isDropped, setIsDropped] = useState(false);

  const [showDeleteSourceWindow, setShowDeleteSourceWindow] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState('');
  const [deletingSourceName, setDeletingSourceName] = useState('');

  const lastAddedSource = useSelector((state) => state.parsers.lastAddedSource);
  const observRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!namesMap || !namesMap.size || namesMap.size !== allSources.length) {
      const newNamesMap = new Map();
      const newCrawlerIds = new Map();
      const newCrawlerToParserIds = new Map();
      const newCrawlerToParser = new Map();
      const newRemovableList = new Map();
      allSources.forEach((source) => {
        newNamesMap.set(source.title, source.name);
        newRemovableList.set(source.title, typeof source.removable === 'undefined' ? true : source.removable);
        newCrawlerIds.set(source.id, source.title);
        const foundParser = allParsers.find((parser) => parser.id === source.parserId);

        if (foundParser) {
          newCrawlerToParser.set(source.title, foundParser.parser);
        }

        if (!newCrawlerToParserIds.has(source.parserId)) {
          newCrawlerToParserIds.set(source.parserId, []);
        }

        const parserNames = newCrawlerToParserIds.get(source.parserId);
        newCrawlerToParserIds.set(source.parserId, [...parserNames, source.title]);
      });
      setParsersInfo({
        names: newNamesMap,
        ids: newCrawlerIds,
      });
      setNamesMap(newNamesMap);
      setCrawlerIds(newCrawlerIds);
      setCrawlerToParserIds(newCrawlerToParserIds);
      setCrawlerToParser(newCrawlerToParser);
      setRemovableList(newRemovableList);
    }
  }, [allSources]);

  useEffect(() => {
    if (currentTemplate
      && currentTemplate.crawlers
      && currentTemplate.crawlers.length
      && crawlerIds) {
      const newRightList = [];

      currentTemplate.crawlers.forEach((crawler) => {
        newRightList.push(crawlerIds.get(crawler.crawlerId));
      });
      const allSourcesTitles = allSources.map((source) => source.title);
      const newLeftList = allSourcesTitles.filter((source) => !newRightList.includes(source));

      setRightList(newRightList);
      setLeftList({
        filtered: newLeftList,
        notFiltered: newLeftList,
      });

      const selectedParsers = newRightList.map((name) => crawlerToParser.get(name));
      changeParserList(selectedParsers);
      setRefreshButEnable(false);
    } else if (currentTemplate
      && currentTemplate.crawlers
      && !currentTemplate.crawlers.length) {
      setRightList([]);
      changeParserList([]);
    }
  }, [currentTemplate, allSources, crawlerIds, crawlerToParser]);

  const endDragFunc = useCallback(() => {
    setDraggedItem('');
  }, []);

  const refreshSources = (list) => {
    const sourcesToTemplate = [];

    const innerRightList = list || rightList;
    const allSourcesCopy = JSON.parse(JSON.stringify(allSources));

    allSourcesCopy.forEach((parsValue) => {
      // eslint-disable-next-line no-param-reassign
      parsValue.isMarked = false;
    });

    innerRightList.forEach((sourceTitle) => {
      allSourcesCopy.forEach((source) => {
        if (source.title === sourceTitle) {
          // eslint-disable-next-line no-param-reassign
          source.isMarked = true;

          sourcesToTemplate.push(source);
        }
      });
    });

    const otherSources = allSourcesCopy.filter((pars) => !innerRightList.includes(pars.title));

    const userNewTemplate = createDefaultFiltersTemplate(sourcesToTemplate);
    dispatch(setSources([...sourcesToTemplate, ...otherSources]));
    dispatch(setToNoneTemplate(userNewTemplate));
  };

  if (isDropped && currentTemplate && currentTemplate.crawlers) {
    let pass = false;
    const { crawlers } = currentTemplate;
    const templateTitles = crawlers.map((crawler) => crawlerIds.get(crawler.crawlerId));

    if (templateTitles.length === rightList.length) {
      for (let i = 0; i < rightList.length; i += 1) {
        if (templateTitles[i] !== rightList[i]) {
          pass = true;
        }
      }
    } else {
      pass = true;
    }

    setIsDropped(false);

    if (pass) {
      // refreshSources();
    }
  }

  const swap = (ar, a, b) => {
    const arr = [...ar];
    // eslint-disable-next-line prefer-destructuring
    arr[a] = arr.splice(b, 1, arr[a])[0];
    return arr;
  };

  const dropFuncLeft = (item) => {
    const gost = item.value;

    if (rightList.includes(gost)) {
      const arrWithoutGost = rightList.filter((val) => val !== gost);
      const newList = [...leftList.filtered];
      const newNotFiltered = [...leftList.notFiltered];
      newList.push(gost);
      newNotFiltered.push(gost);

      setLeftList({
        filtered: newList,
        notFiltered: newNotFiltered,
      });
      setRightList(arrWithoutGost);
      setRefreshButEnable(true);
    }
    setIsDropped(true);
  };

  const dropFuncRight = (item) => {
    const gost = item.value;

    if (leftList.filtered.includes(gost)) {
      const arrWithoutGost = leftList.filtered.filter((val) => val !== gost);
      const arrNotFilteredWithoutGost = leftList.notFiltered.filter((val) => val !== gost);
      const newList = [...rightList];
      newList.push(gost);

      setLeftList({
        filtered: arrWithoutGost,
        notFiltered: arrNotFilteredWithoutGost,
      });
      setRightList(newList);
      setRefreshButEnable(true);
    }
    setIsDropped(true);
  };

  const itemHoverFunc = useCallback((gost, host) => {
    if (gost !== host) {
      let newLeftList = leftList.filtered;
      let newLeftListNotFiltered = leftList.notFiltered;
      let newRightList = rightList;

      if (leftList.filtered.includes(gost) && rightList.includes(host)) {
        newLeftList = leftList.filtered.filter((val) => val !== gost);
        newLeftListNotFiltered = leftList.notFiltered.filter((val) => val !== gost);
        newRightList.push(gost);
      } else if (rightList.includes(gost) && rightList.includes(host)) {
        let gostI;
        let hostI;
        rightList.forEach((item, index) => {
          if (item === gost) {
            gostI = index;
          }
          if (item === host) {
            hostI = index;
          }
        });

        if (Math.abs(gostI - hostI) === 1) {
          newRightList = swap(rightList, gostI, hostI);
        } else {
          const arrWithoutGost = rightList.filter((val) => val !== gost);
          let newHostI;
          arrWithoutGost.forEach((val, ind) => {
            if (val === host) {
              newHostI = ind;
            }
          });
          const leftP = arrWithoutGost.slice(0, newHostI);
          const rightP = arrWithoutGost.slice(newHostI, arrWithoutGost.length);

          newRightList = [...leftP, gost, ...rightP];
        }
      }

      setLeftList({
        filtered: newLeftList,
        notFiltered: newLeftListNotFiltered,
      });
      setRightList(newRightList);
      setRefreshButEnable(true);
    }
  }, [leftList, rightList]);

  const onSearchChange = (event, side) => {
    if (side === 'left') {
      setLeftSearchValue(event.target.value);
    } else {
      setRightSearchValue(event.target.value);
    }
  };

  const hoverFunc = (item) => {
    if (item.value !== draggedItem) {
      setDraggedItem(item.value);
    }
  };

  const clearClick = (side) => {
    if (side === 'left') {
      setLeftSearchValue('');
    } else {
      setRightSearchValue('');
    }
  };

  const deleteSourceHandler = (itemName) => {
    if (!itemName) {
      return;
    }

    const sourceToDel = allSources.find((item) => item.title === itemName);

    if (!sourceToDel || !sourceToDel.id) {
      return;
    }

    (async () => {
      setWaiting(true);
      try {
        const response = await RequestsApi.deleteSource(sourceToDel.id);

        if (response) {
          dispatch(clearSourceFromTemplate(sourceToDel.id));
          dispatch(setSources(allSources.filter((item) => item.title !== itemName)));
          dispatch(setModelSources(allSources.filter((item) => item.title !== itemName)));
          setShowDeleteSourceWindow(false);
        }
      } catch (err) {
        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError(err.message);
        }
      }
      setWaiting(false);
    })();
  };

  const deleteHandler = (itemName) => {
    setDeletingSourceName(itemName);
    setShowDeleteSourceWindow(true);
  };

  const cancelSourceAction = () => {
    if (waiting) {
      return;
    }

    setError('');
    setShowDeleteSourceWindow(false);
    setDeletingSourceName('');
  };

  const itemMove = (itemName) => {
    let newLeftList;
    let newLeftListNotFiltered;
    let newRightList;

    if (rightList.includes(itemName)) {
      newLeftList = [...leftList.filtered, itemName];
      newLeftListNotFiltered = [...leftList.notFiltered, itemName];
      newRightList = rightList.filter((item) => item !== itemName);
    } else {
      newRightList = [itemName, ...rightList];
      newLeftList = leftList.filtered.filter((item) => item !== itemName);
      newLeftListNotFiltered = leftList.notFiltered.filter((item) => item !== itemName);
    }

    setLeftList({
      filtered: newLeftList,
      notFiltered: newLeftListNotFiltered,
    });
    setRightList(newRightList);
    setRefreshButEnable(true);
    // refreshSources(newRightList);
  };

  const dblClickHandler = (item) => {
    itemMove(item);
  };

  const filterList = (side) => {
    let filteredList;

    if (side === 'left') {
      let filteredByParser = [...leftList.filtered];

      if (parserValue) {
        const values = crawlerToParserIds.has(parserValue)
          ? crawlerToParserIds.get(parserValue)
          : [];
        filteredByParser = leftList.notFiltered.filter((val) => values.includes(val));
      }

      if (leftSearchValue) {
        filteredList = filteredByParser
          .filter((item) => item.toLowerCase().indexOf(leftSearchValue.toLowerCase()) !== -1);
      } else {
        filteredList = [...filteredByParser];
      }
    } else if (rightSearchValue) {
      filteredList = rightList
        .filter((item) => item.toLowerCase().indexOf(rightSearchValue.toLowerCase()) !== -1);
    } else filteredList = [...rightList];

    if (!namesMap) {
      return [];
    }

    const deleteCallback = side === 'right' ? itemMove : deleteHandler;

    const result = filteredList.map((item, index) => {
      const prefix = (
        <div key={`${item}-prefix`} className={styles.leftDigitStyle}>
          {index + 1}
        </div>
      );

      const sourceName = crawlerToParser.get(item);
      const removable = removableList.get(item);
      const useHighlight = lastAddedSource?.name?.split('_')[0] === item;
      const currentRef = useHighlight ? { ref: observRef } : {};
      const { isVisible } = clientBoundRect(observRef, containerRef);
      const scrollContainerElement = document.querySelector('.myBoardHandleScroll');

      if (!isVisible && observRef.current && scrollContainerElement) {
        document.querySelector('.myBoardHandleScroll').scrollTo({
          behavior: 'smooth',
          top: observRef.current.offsetTop - 40,
        });
      }

      return (
        <div
          className={
          classNames(
            styles.itemContainer,
          )
        }
          style={{ width: (side === 'right' ? '100%' : '50%') }}
          key={`${item}-source-item`}
          {...currentRef}
        >
          {side === 'right' ? prefix : null}
          <ParserItem
            useHighlight={useHighlight}
            index={index}
            itemHoverFunc={itemHoverFunc}
            visible={item !== draggedItem}
            deleteShow={side === 'right' || removable}
            itemDelete={deleteCallback}
            iconSrc={`/icons/parsers/${sourceName}.png`}
            isBig={side === 'right'}
            endDragFunc={endDragFunc}
            dblClick={dblClickHandler}
            forceBackground={!!draggedItem}
          >
            {item}
          </ParserItem>
        </div>
      );
    });
    return result;
  };

  const refreshButHandle = () => {
    setVisibility();
    const selectedParsers = rightList.map((name) => crawlerToParser.get(name));
    changeParserList(selectedParsers);
    setLeftSearchValue('');
    setRightSearchValue('');
    refreshSources(rightList);
    setRefreshButEnable(false);
  };

  const moveAllSources = (side) => {
    const newList = [...leftList.filtered, ...rightList];
    let newListNotFiltered = [];
    let leftVal;
    let rightVal;

    if (side === 'left') {
      newListNotFiltered = leftList.notFiltered.filter((val) => !newList.includes(val));
      leftVal = [];
      rightVal = newList;
    } else {
      newListNotFiltered = [...leftList.notFiltered, ...rightList];
      leftVal = newList;
      rightVal = [];
    }

    setLeftList({
      filtered: leftVal,
      notFiltered: newListNotFiltered,
    });
    setRightList(rightVal);
    setLeftSearchValue('');
    setRightSearchValue('');
    setRefreshButEnable(true);
    // refreshSources(rightVal);
  };

  const bigBlock = (
    <DroppableParserBlock
      key="droppable-big-block"
      cols={2}
      title="Sources"
      clickStr="Select all sources"
      side="left"
      moveAllSources={moveAllSources}
      onSearchChange={onSearchChange}
      clearClick={clearClick}
      searchValue={leftSearchValue}
      dropFunc={dropFuncLeft}
      hover={hoverFunc}
      currentParser={parserValue}
    >
      {filterList('left')}
    </DroppableParserBlock>
  );

  const smallBlock = (
    <DroppableParserBlock
      blockRef={containerRef}
      useHandleChildScroll
      key="droppable-small-block"
      cols={1}
      title="My board"
      clickStr="Clear all source"
      side="right"
      moveAllSources={moveAllSources}
      onSearchChange={onSearchChange}
      clearClick={clearClick}
      searchValue={rightSearchValue}
      dropFunc={dropFuncRight}
      hover={hoverFunc}
      refreshButton={refreshButHandle}
      refreshButEnable={refreshButEnable}
    >
      {filterList('right')}
    </DroppableParserBlock>
  );
  let firstBlock;
  let secondBlock;

  if (leftDirect) {
    firstBlock = bigBlock;
    secondBlock = smallBlock;
  } else {
    firstBlock = smallBlock;
    secondBlock = bigBlock;
  }

  const changeDirection = () => {
    setLeftDirect(!leftDirect);
  };

  const filterByParser = (value) => {
    if (parserValue === value) {
      setLeftList({
        ...leftList,
        filtered: leftList.notFiltered,
      });

      setParserValue(null);
    } else {
      const values = crawlerToParserIds.has(value) ? crawlerToParserIds.get(value) : [];
      const filtered = leftList.notFiltered.filter((val) => values.includes(val));

      setLeftList({
        ...leftList,
        filtered,
      });

      setParserValue(value);
    }
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.mainContainer}>
          <ParserBlock
            selectedId={parserValue}
            onChange={filterByParser}
          />
          <div>
            {firstBlock}
          </div>
          <div
            role="button"
            tabIndex="0"
            className={styles.arrows}
            onClick={changeDirection}
            onKeyUp={changeDirection}
          >
            <img src={arrows} alt="dnd-arrows" />
          </div>
          <div>
            {secondBlock}
          </div>
        </div>
      </DndProvider>
      <DeleteSourceComponent
        waiting={waiting}
        error={error}
        cancelHandler={cancelSourceAction}
        show={showDeleteSourceWindow}
        sourceName={deletingSourceName}
        deleteHandler={deleteSourceHandler}
      />
    </>
  );
};

export default ParserSortWidget;
