import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { getNormalizeDate } from 'helpers';
import RequestsApi from 'services/requestsApi';
import NotificationService from 'services/NotificationService';
import updateIcon from 'assets/icons/parser-updater-icon.png';
import NotiffComponent from 'components/NotiffComponent';
import styles from './style.module.scss';

const ParserUpdaterComponent = ({
  parser = {}, onUpdate = () => {
  },
}) => {
  const [loading, setLoading] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const interval = useRef(() => {
  });

  const validDate = getNormalizeDate(parser.dateUpdated);

  const onClick = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const resRequestId = await RequestsApi.executeParserUpdate(parser.name);
    setRequestId(resRequestId.data.requestId);
  };

  const notiffText = (<FormattedMessage id="socialWidget.updated" values={{ parserName: parser.title }} />);

  const requestUpdate = useCallback(async () => {
    const res = await RequestsApi.checkExecutionUpdate(parser.name, requestId);
    if (res && res.status === 202) {
      return;
    }

    NotificationService.addNotification(null,
      null,
      { content: (<NotiffComponent text={notiffText} parserId={parser.id} />) });

    clearInterval(interval.current);
    setLoading(false);
    onUpdate();
  }, [requestId]);

  const checkUpdate = useCallback(async () => {
    await requestUpdate();
    interval.current = setInterval(async () => {
      await requestUpdate();
    }, 1000 * process.env.REACT_APP_TIMEOUT_TO_CHECK_UPDATE);
  }, [requestUpdate]);

  useEffect(() => {
    if (!requestId) {
      return;
    }

    checkUpdate();
  }, [requestId, checkUpdate]);

  return (
    <div className={classNames(styles.updaterComp)}>

      <div className={styles.updBlock}>
        <div>Updated</div>
        <div className={styles.updatedTime}>{parser.dateUpdated && validDate}</div>
      </div>
      <div
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyPress={onClick}
        className={classNames(styles.updateBtn, { [styles.rotateUpdater]: loading })}
      >
        <img src={updateIcon} alt="" />
      </div>
    </div>
  );
};

export default ParserUpdaterComponent;
