import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { parserNamesWithoutImgs } from '../../../consts';
import socialWidgetStyles from '../../SocialsWidgetBlock/styles.module.scss';
import ImgWithLoader from '../../ImgWithLoader';
import { handleHtmlSpecSymbols } from '../../../helpers';
import Text from '../../Text';
import BadgeContainer from '../../BadgeContainer';
import LoadingComponent from '../../LoadingComponent';
import WebSocketApi from '../../../services/webSocketApi';
import RequestsApi from '../../../services/requestsApi';
import CommentItem from '../CommentItem';

const CommentModal = (props) => {
  const { data, parserName } = props;

  const [errorMessage, setErrorMessage] = useState(null);
  const [comments, setComments] = useState([]);
  const [loadingComments, setCommentsLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [page, setPage] = useState(1);
  const [allCommentsLoaded, setAllCommentsLoaded] = useState(false);
  const [sendMessage, setMessageSend] = useState(false);
  const commentsPerRequest = 10;

  async function fetchData() {
    if (!allCommentsLoaded) {
      setCommentsLoading(true);
      const fetchedComments = await RequestsApi.getComments(data.id, page);
      const reversedFetchedData = fetchedComments.data.reverse();
      setComments([...reversedFetchedData, ...comments]);
      if (reversedFetchedData.length < commentsPerRequest) {
        setAllCommentsLoaded(true);
      }
      setPage(page + 1);
      setCommentsLoading(false);
    }
  }

  const commentsRef = useRef();
  commentsRef.current = comments;
  useEffect(() => {
    WebSocketApi.subscribeToComments(data.id, (messageData) => {
      setComments([...commentsRef.current, messageData.comment]);
    });
    return () => {
      WebSocketApi.unSubscribeToComments(data.id);
    };
  }, [data.id]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const textAreaRef = useRef(null);

  const addComment = () => {
    const el = textAreaRef.current;

    if (el.value !== '' && !sendMessage) {
      setMessageSend(true);
      const commentData = {
        message: el.value,
        id: data.id,
      };

      WebSocketApi.sendComment(commentData).then((e) => {
        if (!e.error) {
          setComments([...comments, e.comment]);
          el.value = '';
          el.blur();
          setSubmitDisable(true);
          setMessageSend(false);
        } else {
          setErrorMessage(e.message);
        }
      });
    }
  };

  const scrollRef = useRef(null);
  const onCommentsScroll = (e) => {
    if (e.target.scrollTop < 100 && !loadingComments) {
      fetchData();
    }
  };

  const commentItems = comments.map((comment) => {
    const {
      dateCreated,
      body,
      id,
      user,
    } = comment;
    return (<CommentItem key={id} user={user} date={dateCreated} body={body} />);
  });

  return (
    <div className={styles.commentsModalContent}>
      <div className={styles.commentsModalHeader}>
        <div className={styles.leftHeaderColumn}>
          {parserNamesWithoutImgs.includes(parserName)
            ? (<div className={socialWidgetStyles.breakLine} />)
            : (
              <ImgWithLoader
                parserName={parserName}
                imgUrl={handleHtmlSpecSymbols(data.imgUrl)}
                imgClass={styles.commentsWindowImage}
              />
            )}
        </div>
        <div className={styles.rightHeaderColumn}>
          <Text
            additionalStyles={styles.modalWidgetText}
            content={data.title || data.description || 'empty'}
          />
          <BadgeContainer data={data} parserName={parserName} />
        </div>
      </div>
      <div className={styles.commentsModalBody} ref={scrollRef} onScroll={onCommentsScroll}>
        <div>
          {commentItems}
          <LoadingComponent text="Loading comments" show={loadingComments} />
        </div>
      </div>
      <div className={styles.commentsModalFooter}>
        {errorMessage && (
          <div
            tabIndex={0}
            role="button"
            className={styles.errorMessage}
            onClick={() => {
              setErrorMessage(null);
            }}
            onKeyDown={() => {
              setErrorMessage(null);
            }}
          >
            {errorMessage}
          </div>
        )}
        <textarea
          className={styles.textarea}
          disabled={sendMessage}
          ref={textAreaRef}
          onKeyDown={(e) => {
            const el = textAreaRef.current;
            const commentEnable = el.value.length > 2;
            setSubmitDisable(!commentEnable);
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (commentEnable) {
                addComment();
              }
              return false;
            }
            return true;
          }}
        />
        <button
          className={styles.writeCommentButton}
          type="button"
          onClick={addComment}
          disabled={submitDisable || sendMessage}
        >
          <img
            alt="badge_altimg"
            src="/icons/modal/send-comment-icon.svg"
          />
        </button>
      </div>
    </div>
  );
};

export default CommentModal;
