const initialState = {
  allParsers: [],
  modelParsers: [],
  allSources: [],
  modelSources: [],
  lastAddedSource: null,
};

export const SET_PARSERS = 'SET_PARSERS';
export const SET_SOURCES = 'SET_SOURCES';
export const SET_SOURCES_OPTIONS = 'SET_SOURCES_OPTIONS';
export const SET_MODEL_PARSERS = 'SET_MODEL_PARSERS';
export const SET_MODEL_SOURCES = 'SET_MODEL_SOURCES';
export const MARK_SINGLE_PARSER = 'MARK_SINGLE_PARSER';
export const MARK_SINGLE_SOURCE = 'MARK_SINGLE_SOURCE';
export const MARK_ALL_PARSERS = 'MARK_ALL_PARSERS';
export const MARK_ALL_SOURCES = 'MARK_ALL_SOURCES';
export const RESTORE_ALL_PARSERS = 'RESTORE_ALL_PARSERS';
export const RESTORE_ALL_SOURCES = 'RESTORE_ALL_SOURCES';
export const READ_TEMPLATE = 'READ_TEMPLATE';
export const SET_LAST_ADDED_CUSTOM_SOURCE = 'SET_LAST_ADDED_CUSTOM_SOURCE';

const arrayDateCreated = [
  '5fc12faac669e2003383e47c',
  '5fd1f220896b7e002a472dac',
  '5fcfb1abebeff9007523af0b',
  '5fcfb1af04f5750071a896cb',
  '5fcfbfa06ab0630061b379a0',
  '5fcfbfba0153b8007e76b7f0',
];

const redditId = '5fc12fa5eb1ec400231d0706';

export const readTemplate = (data) => ({
  type: READ_TEMPLATE,
  data,
});

export const restoreAllParsers = () => ({
  type: RESTORE_ALL_PARSERS,
});

export const restoreAllSources = () => ({
  type: RESTORE_ALL_SOURCES,
});

export const setModelParsers = (data) => ({
  type: SET_MODEL_PARSERS,
  data,
});

export const setModelSources = (data) => ({
  type: SET_MODEL_SOURCES,
  data,
});

export const markAllParsers = (data) => ({
  type: MARK_ALL_PARSERS,
  data,
});

export const markAllSources = (data) => ({
  type: MARK_ALL_SOURCES,
  data,
});

export const setParsers = (data) => ({
  type: SET_PARSERS,
  data,
});

export const setSources = (data) => ({
  type: SET_SOURCES,
  data,
});

export const setSourcesOptions = (data) => ({
  type: SET_SOURCES_OPTIONS,
  data,
});

export const markSingleParser = (data) => ({
  type: MARK_SINGLE_PARSER,
  data,
});

export const markSingleSource = (data) => ({
  type: MARK_SINGLE_SOURCE,
  data,
});

export const setLastCustomSource = (source) => ({
  type: SET_LAST_ADDED_CUSTOM_SOURCE,
  source,
});

const parsers = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARSERS:
      return { ...state, allParsers: action.data };

    case SET_SOURCES: {
      const nParsers = JSON.parse(JSON.stringify(state.modelParsers));
      const newParsers = [];

      nParsers.forEach((parser) => {
        // eslint-disable-next-line no-param-reassign
        parser.isMarked = false;
        const markedSources = action.data.filter(
          (source) => source.isMarked === true && source.parserId === parser.id,
        );

        if (markedSources.length) {
          // eslint-disable-next-line no-param-reassign
          parser.isMarked = true;
        }

        newParsers.push(parser);
      });

      return { ...state, allSources: action.data, allParsers: newParsers };
    }

    case SET_SOURCES_OPTIONS: {
      const source = action.data;
      const { allSources } = state;
      const currentIndex = allSources.map((s) => s.id).indexOf(source.id);

      if (currentIndex >= 0) {
        allSources[currentIndex] = source;
      }

      return { ...state, allSources };
    }

    case MARK_SINGLE_PARSER: {
      const newAllParsers = state.allParsers.map((parser) => {
        const p = { ...parser };
        if (action.data.title && parser.title === action.data.title) {
          p.isMarked = action.data.isMarked;
        }
        return p;
      });
      return { ...state, allParsers: newAllParsers };
    }

    case MARK_SINGLE_SOURCE: {
      const newAllSources = state.allSources.map((parser) => {
        const p = { ...parser };
        if (action.data.title && parser.title === action.data.title) {
          p.isMarked = action.data.isMarked;
        }
        return p;
      });
      return { ...state, allSources: newAllSources };
    }

    case MARK_ALL_PARSERS: {
      const newAllParsers = state.allParsers.map((parser) => {
        const p = parser;
        p.isMarked = action.data;
        return p;
      });
      return { ...state, allParsers: newAllParsers };
    }

    case MARK_ALL_SOURCES: {
      const newAllSources = state.allSources.map((parser) => {
        const p = parser;
        p.isMarked = action.data;
        return p;
      });
      return { ...state, allSources: newAllSources };
    }

    case RESTORE_ALL_PARSERS: {
      const newParsers = JSON.parse(JSON.stringify(state.modelParsers));
      return { ...state, allParsers: newParsers };
    }

    case RESTORE_ALL_SOURCES: {
      const newSources = JSON.parse(JSON.stringify(state.modelSources));
      return { ...state, allSources: newSources };
    }

    case SET_MODEL_PARSERS: {
      return { ...state, modelParsers: action.data };
    }

    case SET_MODEL_SOURCES: {
      return { ...state, modelSources: action.data };
    }

    case READ_TEMPLATE: {
      const template = action.data;
      const { crawlers } = template;

      const nSources = JSON.parse(JSON.stringify(state.modelSources));
      const nParsers = JSON.parse(JSON.stringify(state.modelParsers));
      const newParsers = [];
      const otherSources = [];
      const templateSources = [];

      nSources.forEach((source) => {
        // eslint-disable-next-line no-param-reassign
        source.isMarked = false;

        crawlers.forEach((crawler, ind) => {
          if (crawler.crawlerId === source.id) {
            const copyCrawOptions = JSON.parse(JSON.stringify(crawler.options));
            if (copyCrawOptions.sortBy.dir !== '-1' && copyCrawOptions.sortBy.dir !== '1') {
              copyCrawOptions.sortBy.dir = '-1';
            }

            if (copyCrawOptions.period.unit === 'd' && copyCrawOptions.period.value === '2') {
              copyCrawOptions.period.value = '1';
            }

            if (copyCrawOptions.sortBy.field === 'date') {
              if (arrayDateCreated.includes(crawler.crawlerId)) {
                copyCrawOptions.sortBy.field = 'dateCreated';
              } else {
                copyCrawOptions.sortBy.field = 'datePublished';
              }
              if (crawler.crawlerId === redditId) {
                copyCrawOptions.sortBy.field = 'statistic.commentCount';
              }
            }
            // eslint-disable-next-line no-param-reassign
            source.options = copyCrawOptions;
            // eslint-disable-next-line no-param-reassign
            source.isMarked = true;
            templateSources[ind] = source;
          }
        });

        if (!source.isMarked) {
          otherSources.push(source);
        }
      });

      const allSources = [...templateSources, ...otherSources];

      nParsers.forEach((parser) => {
        // eslint-disable-next-line no-param-reassign
        parser.isMarked = false;
        const markedSources = allSources.filter(
          (source) => source.isMarked === true && source.parserId === parser.id,
        );

        if (markedSources.length) {
          // eslint-disable-next-line no-param-reassign
          parser.isMarked = true;
        }

        newParsers.push(parser);
      });

      return { ...state, allSources, allParsers: newParsers };
    }
    case SET_LAST_ADDED_CUSTOM_SOURCE: {
      return { ...state, lastAddedSource: action.source };
    }

    default:
      return state;
  }
};

export default parsers;
