import { useState, useCallback, useRef } from 'react';
import RequestsApi from 'services/requestsApi';

async function sleep(milSecond = 10) {
  return new Promise((resolve) => {
    setTimeout(resolve, milSecond);
  });
}

function randTime(min = 1, max = 100) {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

const useRequest = (maxTries = 1) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const countRunRequests = useRef(1);

  const runRequest = useCallback(async (url) => {
    try {
      const res = await RequestsApi.authRequest(url, 'get');
      setData(res.data);
      setLoading(false);
    } catch (e) {
      countRunRequests.current += 1;
      if (maxTries >= countRunRequests.current) {
        await sleep(randTime(1000, 6000));
        await runRequest(url);
        return;
      }
      setLoading(false);
      setError(e);
    }
  }, [maxTries]);

  const doRequest = useCallback((url) => {
    setLoading(true);

    (async () => {
      await runRequest(url);
    })();
  }, [runRequest]);

  return {
    loading,
    error,
    data,
    doRequest,
  };
};

export default useRequest;
