export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const setUserProfile = (data) => ({
  type: SET_USER_PROFILE,
  data,
});

const initialState = {
  user: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return { ...state, user: action.data };
    default: return state;
  }
};

export default user;
