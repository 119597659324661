import { randomBytes } from 'crypto';
import {
  TIME_FILTER_CONFIG,
  TEMPLATE_TIME,
  sizes,
  DEFAULT_PARSER_ICON_LINK,
} from 'consts';
import store from 'store/store';
import { setPageSize } from 'store/reducers/page';

const getShiftedTimestamp = (rawDateString) => {
  const date = new Date(rawDateString);
  if (date.toString() === 'Invalid Date') {
    return null;
  }
  // difference of UTC and EST is 5 hours
  const utcToEstShifting = -5;
  return date.getTime() + utcToEstShifting * 60 * 60 * 1000;
};

export const convertDateToEstString = (rawDate) => {
  const handledDate = getShiftedTimestamp(rawDate);
  return handledDate ? new Date(handledDate).toUTCString().replace('GMT', 'EST') : null;
};

export const getNormalizeDate = (acceptedDate) => {
  const todayDate = new Date(getShiftedTimestamp(Date.now())).getDate();
  const parserUpdatedDate = new Date(getShiftedTimestamp(acceptedDate));
  const parserUpdatedDay = parserUpdatedDate.getDate();

  const parserUpdatedTime = `${parserUpdatedDate.getUTCHours()}:${parserUpdatedDate.getUTCMinutes() < 10
    ? `0${parserUpdatedDate.getUTCMinutes()}` : parserUpdatedDate.getUTCMinutes()}`;

  if (todayDate - parserUpdatedDay === 1) {
    return `yesterday at ${parserUpdatedTime} EST`;
  }

  if (todayDate - parserUpdatedDay === 0) {
    return /* 'Today' */ `today at ${parserUpdatedTime} EST`;
  }

  return `${parserUpdatedDate
    .toLocaleDateString()
    .replaceAll('/', '.')} at ${parserUpdatedTime} EST`;
};

export const createDefaultFiltersTemplate = (parsers) => {
  let parsersCopy;
  const { timeFilter } = store.getState().page;
  const period = { unit: TIME_FILTER_CONFIG[timeFilter.type], value: timeFilter.value };
  const defTemplate = { name: 'none', global: { period } };

  if (!parsers) {
    const { modelSources } = store.getState().parsers;
    parsersCopy = JSON.parse(JSON.stringify(modelSources));
  } else {
    parsersCopy = [...parsers];
  }

  defTemplate.crawlers = parsersCopy.map((parser) => ({
    crawlerId: parser.id,
    options: JSON.parse(JSON.stringify(parser.options)),
  }));

  return defTemplate;
};

export const likeCounterRender = (likeCount) => {
  if (likeCount) {
    if (likeCount < 1000) return likeCount;
    if (likeCount >= 1000 && likeCount < 1000000) {
      let nc = (likeCount / 1000).toFixed(1);
      if (nc.endsWith('.0')) {
        nc = nc.slice(0, -2);
      }
      return `${nc}K`;
    }
    if (likeCount >= 1000000 && likeCount < 1000000000) {
      let nc = (likeCount / 1000000).toFixed(1);
      if (nc.endsWith('.0')) {
        nc = nc.slice(0, -2);
      }
      return `${nc}M`;
    }
  }
  return '0';
};

export const prepareArrayOfObjectsForQuerystring = (paramName, options) => {
  let resultQuerystring = '';
  options.forEach((option, index) => {
    const keys = Object.keys(option);
    keys.forEach((key) => {
      resultQuerystring += `&${paramName}[${index}][${key}]=${option[key]}`;
    });
  });
  return resultQuerystring;
};

export const addResizeWindowListener = () => {
  window.screenSizeValue = '';

  const resizeListener = () => {
    const size = document.documentElement.clientWidth;
    let lab;

    const sm = 566;
    const md = 758;
    const lg = 982;
    const xl = 1190;
    const wide = 2500;

    if (size < sm) {
      lab = sizes.XS;
    }
    if (size >= sm && size < md) {
      lab = sizes.SM;
    }
    if (size >= md && size < lg) {
      lab = sizes.MD;
    }
    if (size >= lg && size < xl) {
      lab = sizes.LG;
    }
    if (size >= xl) {
      lab = sizes.XL;
    }
    if (size >= wide) {
      lab = sizes.WIDE;
    }

    if (window.screenSizeValue !== lab) {
      window.screenSizeValue = lab;
      store.dispatch(setPageSize(lab));
    }
  };
  resizeListener();
  window.addEventListener('resize', resizeListener);
};

export const templateDateConvert = (templateDate) => {
  const { value, unit } = templateDate;
  let type = TEMPLATE_TIME[unit];

  if (value > 1 && type !== 'min') {
    type = `${type}s`;
  }

  return { value, type };
};

const dateDiff = (before, after, cfg, zero = true) => {
  const conf = cfg || ['allSeconds', 'allMinutes', 'allHours', 'seconds', 'minutes', 'hours', 'days'];

  const allSeconds = Math.floor((after - before) / 1000);
  if (allSeconds < 0) {
    return null;
  }
  const allMinutes = Math.floor(allSeconds / 60);
  const seconds = allSeconds - allMinutes * 60;
  const allHours = Math.floor(allMinutes / 60);
  const minutes = allMinutes - allHours * 60;
  let hours = 0;
  let days = 0;

  if (allHours < 24) {
    hours = allHours;
  } else {
    days = Math.floor(allHours / 24);
    hours = allHours - days * 24;
  }

  const rawObj = {
    allSeconds,
    allMinutes,
    allHours,
    seconds,
    minutes,
    hours,
    days,
  };

  const result = {};

  Object.keys(rawObj).forEach((timeUnit) => {
    if (conf.includes(timeUnit)) {
      if (!zero && rawObj[timeUnit] > 0) {
        result[timeUnit] = rawObj[timeUnit];
      }
      if (zero) {
        result[timeUnit] = rawObj[timeUnit];
      }
    }
  });
  return result;
};

const dateAddNamesEn = (dateDiffVal, cfg) => {
  if (!dateDiffVal) {
    return null;
  }

  const defConf = {
    days: ['day', 'days'],
    hours: ['hour', 'hours'],
    minutes: ['minute', 'minutes'],
    seconds: ['second', 'seconds'],
  };

  const conf = { ...defConf, ...cfg };

  const dateDiffKeys = Object.keys(dateDiffVal);
  const confKeys = Object.keys(conf);
  const keys = dateDiffKeys.filter((v) => confKeys.includes(v));

  const result = {};

  if (keys.length === 0) {
    return result;
  }

  keys.forEach((key) => {
    const ddk = dateDiffVal[key];
    const index = ddk === 1 ? 0 : 1;
    result[key] = `${ddk} ${conf[key][index]}`;
  });
  return result;
};

const dateDiffStrBuilder = (dateObj) => {
  let result = '';

  if (!dateObj) {
    return null;
  }

  if (dateObj && !dateObj.minutes && !dateObj.hours && !dateObj.days) {
    return 'Just published';
  }

  if (dateObj.days) {
    result += dateObj.days;
    if (dateObj.hours) {
      result += ` ${dateObj.hours}`;
    }
  } else {
    if (dateObj.hours) {
      result += dateObj.hours;
    }
    if (dateObj.minutes) {
      if (dateObj.hours) {
        result += ' ';
      }
      result += dateObj.minutes;
    }
  }

  result += ' ago';
  return result;
};

export const getDateDiff = (dateAfterString) => {
  const dateDiffConfig = ['days', 'hours', 'minutes'];
  const dateDiffVal = dateDiff(new Date(dateAfterString), new Date(), dateDiffConfig, false);
  const namesConf = {
    minutes: ['min', 'mins'],
  };
  return dateDiffStrBuilder(dateAddNamesEn(dateDiffVal, namesConf));
};

export const handleHtmlSpecSymbols = (rawStr) => {
  let tempElem = document.createElement('div');
  tempElem.innerHTML = rawStr;
  const result = tempElem.innerText;
  tempElem = null;
  return result;
};

const bth = [];
for (let i = 0; i < 256; i += 1) {
  bth[i] = (i + 0x100).toString(16).substr(1);
}
export function uuid() {
  const buf = randomBytes(16);
  buf[6] = (buf[6] && 0x0f) || 0x40;
  buf[8] = (buf[8] && 0x3f) || 0x80;
  let i = 0;
  // eslint-disable-next-line no-return-assign
  return [
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    '-',
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    '-',
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    '-',
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    '-',
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    bth[buf[i += 1]],
    bth[buf[i]],
  ].join('');
}

export const urlPattern = /((http|https):\/\/)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)/;

export const onImageError = (e, size = 17) => {
  e.target.width = size;
  e.target.height = size;
  e.target.onerror = null;
  e.target.src = DEFAULT_PARSER_ICON_LINK;
};
