import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useRequest from 'hooks/useRequest';
import SocialsWidgetContainer from 'components/SocialsWidgetContainer';
import Skeleton from 'components/Skeleton';
import ParsersPaginationComponent from 'components/ParsersPaginationComponent';
import RequestsApi from 'services/requestsApi';
import {
  parserNamesWithoutImgs, sizes, SORTING_OPTIONS_NAME, TIME_FILTER_CONFIG,
} from 'consts';
import { prepareArrayOfObjectsForQuerystring } from 'helpers';
import styles from './styles.module.scss';

const SocialsWidget = (props) => {
  const pageSize = useSelector((store) => store.page.pageSize);
  const [page, setPage] = useState(1);
  const [sourcesData, setSourcesData] = useState([]);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [lastJobStatus, setLastJobStatus] = useState('');
  const { source, parserType } = props;
  const postsPerPage = pageSize === sizes.WIDE ? 17 : 9;
  const postsPerPageForBrif = pageSize === sizes.WIDE ? 18 : 8;

  const linkForSeeAll = `/parser-data/${source.name}`;

  const sortingPostfix = `sortby_field=${source.options.sortBy.field}&sortby_direction=${source.options.sortBy.dir}&period_type=${source.options.period.unit}&period_value=${source.options.period.value}`;
  const {
    data, doRequest, loading,
  } = useRequest(5);
  const timeFilter = useSelector((store) => store.page.timeFilter);
  const searchString = useSelector((store) => store.page.search);
  const lastAddedSource = useSelector((state) => state.parsers.lastAddedSource);

  const statusesToNotUpdatePosts = ['waiting', 'delayed', 'failed', 'paused'];

  const doRequestAndgenerateRequestParams = useCallback(() => {
    const tf = source.options.period || {
      value: timeFilter.value,
      unit: TIME_FILTER_CONFIG[timeFilter.type],
    };
    const fvar = `${tf.value}${tf.unit}`;
    const sortingOptionsQuerystring = prepareArrayOfObjectsForQuerystring(
      SORTING_OPTIONS_NAME,
      [source.options.sortBy],
    );
    let sectionFilterParam = '';

    if (source.options.sections
      && source.options.sections.length
      && source.options.sections.length > 0) {
      if (source.options.sections[0] === 'All sections') {
        source.options.sections = [];
      } else {
        const filterVal = source.options.sections[0];
        sectionFilterParam = `&filterOptions[0][field]=statistic.articleSection&filterOptions[0][value]=${filterVal}`;
      }
    }
    doRequest(`${process.env.REACT_APP_API_BASE_URL}/post/${source.id}?filterTime=${fvar}&page=1&limit=27${sortingOptionsQuerystring}${sectionFilterParam}`);
  }, [doRequest, source.id, source.options, timeFilter.type, timeFilter.value]);

  const getLastJobStatus = (crawlerId, parser) => {
    RequestsApi.getLastJobStatus(crawlerId, parser).then((jobData = {}) => {
      setLastJobStatus(jobData.jobStatus);
    });
  };

  useEffect(() => {
    if (sourcesData.length) {
      return false;
    }

    if (!lastAddedSource || lastAddedSource?.name !== source.name) {
      return false;
    }

    getLastJobStatus(lastAddedSource.id, lastAddedSource.parser);

    const interval = setInterval(() => {
      getLastJobStatus(lastAddedSource.id, lastAddedSource.parser);
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [lastAddedSource, source.name, sourcesData.length]);

  useEffect(() => {
    if (!data.length && !loading) {
      getLastJobStatus(source.id, parserType);
    }
  }, [data.length, loading, source.name, parserType]);

  useEffect(() => {
    if (!lastJobStatus) {
      return;
    }

    if (statusesToNotUpdatePosts.includes(lastJobStatus)) {
      return;
    }

    doRequestAndgenerateRequestParams();
  }, [lastJobStatus]);

  const normalizePostLink = (posts) => posts.map((post) => {
    const updatedPost = { ...post };
    if (!updatedPost.link.startsWith('//')) {
      updatedPost.link = updatedPost.link && updatedPost.link.includes('http')
        ? updatedPost.link
        : `//${updatedPost.link}`;
    }
    return updatedPost;
  });

  useEffect(() => {
    if (!firstLoaded) {
      setFirstLoaded(true);
    }
    if (data) {
      const slicedData = normalizePostLink(data.slice(0, 27));
      setSourcesData(slicedData);
    }
  }, [data, firstLoaded]);

  useEffect(() => {
    doRequestAndgenerateRequestParams();
  }, [source.id, source.options, source.dateUpdated]);

  useEffect(() => {
    if (!data) return;
    const newSourceData = data.filter((v) => {
      const search = searchString.toLowerCase().trim();
      const searchVariant = v.title || v.description;

      return searchVariant && searchVariant.toLowerCase().indexOf(search) !== -1;
    });
    setSourcesData(normalizePostLink(newSourceData.slice(0, 27)));
    setPage(1);
  }, [searchString, data]);

  const clkLeft = () => {
    const i = page - 1;
    if (i) {
      setPage(i);
    }
  };

  const clkRight = () => {
    const i = page + 1;
    if (i <= Math.ceil(sourcesData.length / postsPerPage)) {
      setPage(i);
    }
  };
  const brif = parserNamesWithoutImgs.includes(source.name);

  const igp = (page - 1) * (brif ? postsPerPageForBrif : postsPerPage);

  let skeletonSize = 'DESKTOP';
  if (pageSize === sizes.WIDE) {
    skeletonSize = 'WIDE';
  }
  if (pageSize < sizes.MD) {
    skeletonSize = 'MOBILE';
  }

  const socialWidgetConfig = pageSize === sizes.WIDE
    ? {
      first: {
        col: 2,
        width: 'lg',
        childs: sourcesData.slice(igp, igp + 1),
      },
      second: {
        col: 8,
        width: 'xmd',
        childs: sourcesData.slice(igp + 1, igp + 13),
      },
      third: {
        col: 2,
        width: 'sm',
        childs: sourcesData.slice(igp + 13, igp + postsPerPage),
      },
    }
    : {
      first: {
        col: 4,
        width: 'lg',
        childs: sourcesData.slice(igp, igp + 1),
      },
      second: {
        col: 4,
        width: pageSize < sizes.MD ? 'sm' : 'md',
        childs: sourcesData.slice(igp + 1, igp + 5),
      },
      third: {
        col: 4,
        width: 'sm',
        childs: sourcesData.slice(igp + 5, igp + postsPerPage),
      },
    };

  const val = brif ? 'md' : 'sm';

  const ctn = (
    <div id={`source_Id_${source.id}`}>
      {sourcesData.length === 0 && (
        <div className={classNames(styles.sWStatusCont,
          { [styles.hidden]: sourcesData.length > 0 })}
        >
          {lastJobStatus ? `This source in ${lastJobStatus} status` : '(Empty Data)'}
        </div>
      )}

      {parserNamesWithoutImgs.includes(source.name) ? (
        <div className={classNames(styles.sWMBlock, 'row')}>
          <SocialsWidgetContainer
            size="md"
            parserWithOutImg
            childs={sourcesData.slice(igp, postsPerPageForBrif * page)}
            col={socialWidgetConfig.second.col}
            width={pageSize === sizes.WIDE ? 'xmd' : 'smd'}
            parserName={source.name}
            sortingPostfix={sortingPostfix}
            additionalStyles={pageSize <= sizes.MD ? styles.lastContainer : {}}
            brif={brif}
          />
        </div>
      ) : (
        <div className={classNames(styles.sWMBlock, 'row')}>
          <SocialsWidgetContainer
            size={brif ? 'md' : 'lg'}
            childs={socialWidgetConfig.first.childs}
            col={socialWidgetConfig.first.col}
            width={socialWidgetConfig.first.width}
            parserName={source.name}
            sortingPostfix={sortingPostfix}
            additionalStyles={brif && (pageSize <= sizes.MD ? styles.lastContainer : {})}
            brif={brif}
          />
          <SocialsWidgetContainer
            childs={socialWidgetConfig.second.childs}
            size={pageSize < sizes.MD ? val : 'md'}
            col={socialWidgetConfig.second.col}
            width={socialWidgetConfig.second.width}
            parserName={source.name}
            additionalStyles={brif && (pageSize <= sizes.MD ? styles.lastContainer : {})}
            sortingPostfix={sortingPostfix}
            brif={brif}
          />

          <SocialsWidgetContainer
            childs={socialWidgetConfig.third.childs}
            size={brif ? 'md' : 'sm'}
            col={socialWidgetConfig.third.col}
            width={socialWidgetConfig.third.width}
            additionalStyles={brif ? {} : styles.lastContainer}
            parserName={source.name}
            sortingPostfix={sortingPostfix}
            brif={brif}
          />
        </div>
      )}

      <div className={classNames('row', { [styles.hidden]: sourcesData.length === 0 })}>
        <div className={classNames(styles.sWBottomPanel, 'col-12')}>
          <div className={styles.sWDivider} />
          <Link to={`${linkForSeeAll}?${sortingPostfix}`} className={styles.sWSeeAllLink}>
            <FormattedMessage id="socialWidget.seeAll" />
          </Link>
          <ParsersPaginationComponent
            pageNum={page}
            totalPages={Math.ceil(sourcesData.length / postsPerPage)}
            clkLeft={clkLeft}
            clkRight={clkRight}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.sWCont)}>
      {loading ? <Skeleton size={skeletonSize} /> : ctn}
    </div>
  );
};
export default SocialsWidget;
