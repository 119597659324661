import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BreadCrumpComponent from 'components/BreadCrumpComponent';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import SortingPostsFilter from '../SortingPostsFilter';
import RequestsApi from '../../services/requestsApi';
import store from '../../store/store';
import { setParsers } from '../../store/reducers/parsers';

const InnerPageWrapper = (props) => {
  const {
    color,
    title,
    parserId,
    logoLink = '',
    itemSize,
    parserInfo,
    waitingState,
    itemClkHandler = null,
    switchButtonShow = false,
    pathRoute = '',
    children,
    filterPosts,
    sortingPostfix,
  } = props;

  useEffect(() => {
    (async () => {
      const parserRes = await RequestsApi.authRequest(`${process.env.REACT_APP_API_BASE_URL}/parser`, 'get');
      const parserItems = parserRes.data;

      store.dispatch(setParsers(parserItems));
    })();
  }, []);

  const { allParsers } = useSelector((st) => st.parsers);
  const foundParser = useMemo(() => {
    const item = allParsers.find((parserItem) => parserItem.id === parserId);

    return item ? item.parser : '';
  }, [allParsers, parserId]);
  const [isParserIcon, setIsParserIcon] = useState(true);

  const errorLoadLogoHandler = () => {
    setIsParserIcon(false);
  };

  const breadCrumpComponent = (
    <BreadCrumpComponent pathRoute={pathRoute} />
  );

  const sbut = !switchButtonShow || waitingState;

  return (
    <div className={styles.mpCont}>
      <Header />
      <div className={classNames(styles.mainPageContentCont, 'row px-3 px-md-0')}>
        <div className="col-12">
          <div className={classNames('row', styles.contStyle)}>

            <div
              className={classNames('col-12 col-md-10 col-lg-8', styles.panelCont, { [styles.panelHide]: !waitingState })}
            >
              <div className={classNames('row', styles.panelWrapper)}>
                <div className={classNames('col-sm-8 col-12', styles.leftBlockWrap)}>

                  <Link
                    className={classNames(styles.leftBlock, { [styles.hideLogo]: waitingState })}
                    to={`${logoLink}?${sortingPostfix}`}
                  >
                    {isParserIcon && (
                      <img src={`/icons/parsers/${foundParser}.png`} alt="img_logo_soc" onError={errorLoadLogoHandler} />)}
                    <div style={{ color }}>{title}</div>
                  </Link>

                  {!waitingState && pathRoute && breadCrumpComponent}
                </div>
                <div className={classNames(styles.rightBlock, 'col-sm-4 col-12 mt-4 mb-2 m-sm-0')}>
                  <div
                    className={classNames({ [styles.switchButtonShow]: sbut }, styles.switchButton)}
                  >

                    <button
                      onClick={() => itemClkHandler(true)}
                      className={classNames(styles.switchPostViewBtn,
                        styles.switchToBig,
                        { [styles.activeBtn]: itemSize })}
                      type="button"
                    >
                      <div />
                      <div />
                      <div />
                    </button>
                    <button
                      onClick={() => itemClkHandler(false)}
                      className={classNames(styles.switchPostViewBtn,
                        styles.switchToSmall,
                        { [styles.activeBtn]: !itemSize })}
                      type="button"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </button>
                  </div>

                  <div className={classNames({ [styles.filterHide]: waitingState })}>
                    <SortingPostsFilter
                      filterPosts={filterPosts}
                      parserInfo={parserInfo}
                    />
                  </div>
                </div>
              </div>

            </div>

            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default InnerPageWrapper;
