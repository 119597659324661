import storageService from 'services/StorageService';
import requestsApi from './requestsApi';

class MyWebSocket extends EventTarget {
  constructor() {
    super();
    if (!MyWebSocket.ws) {
      MyWebSocket.ws = this;
      this.number = 0;// Message number
      this.autoReconnectInterval = 5 * 1000;// ms
      this.open(process.env.REACT_APP_WS_URL);
    }
    return MyWebSocket.ws;
  }

  // eslint-disable-next-line class-methods-use-this
  async checkToken() {
    const resp = await requestsApi.healthCheckRequest();
    return resp && resp.healthcheck && resp.healthcheck === 'ok';
  }

  async open(url) {
    const chk = await this.checkToken();
    if (!chk) {
      return;
    }

    this.url = url;
    const token = storageService.storageSource.get('accessToken');
    this.instance = new WebSocket(`${this.url}?token=${token}`);
    this.instance.onopen = () => {
      this.onopen();
    };
    this.instance.onmessage = (data) => {
      this.number += 1;
      this.onmessage(data);
    };
    this.instance.onclose = (e) => {
      switch (e.code) {
        case 1000:// CLOSE_NORMAL
          break;
        default:// Abnormal closure
          this.reconnect(e);
          break;
      }
      this.onclose(e);
    };
    this.instance.onerror = (e) => {
      switch (e.code) {
        case 'ECONNREFUSED':
          this.reconnect(e);
          break;
        default:
          // this.onerror(e);
          break;
      }
    };
  }

  send(data) {
    try {
      this.instance.send(data);
    } catch (e) {
      this.onerror(e);
    }
  }

  reconnect() {
    setTimeout(() => {
      this.open(this.url);
    }, this.autoReconnectInterval);
  }

  // eslint-disable-next-line class-methods-use-this
  onopen(...args) {
    console.log('WebSocketClient: open', args);
  }

  onmessage(e) {
    this.dispatchEvent(new CustomEvent('message', { detail: e.data }));
  }

  // eslint-disable-next-line class-methods-use-this
  onerror(...args) {
    console.log('WebSocketClient: error', args);
  }

  // eslint-disable-next-line class-methods-use-this
  onclose(...args) {
    console.log('WebSocketClient: closed', args);
  }

  getInstance() {
    return this.instance;
  }
}

export default MyWebSocket;
