import React from 'react';
import classNames from 'classnames';
import Button from 'components/Button';
import ParsersPaginationComponent from 'components/ParsersPaginationComponent';
import LgBlock from 'components/Skeleton/blocks/LgBlock';
import MdBlock from 'components/Skeleton/blocks/MdBlock';
import SmBlock from 'components/Skeleton/blocks/SmBlock';
import styles from './style.module.scss';

const Skeleton = ({ size = 'DESKTOP' }) => {
  const content = [];

  content.push(
    <LgBlock key="skel-lg-wind" size={size} />,
  );

  content.push(
    size === 'MOBILE'
      ? <SmBlock size={size} key="skel-sm-wind" />
      : <MdBlock key="skel-md-wind" size={size} />,
  );

  content.push(
    <SmBlock key="skel-sm-wind-last" size={size} />,
  );

  return (
    <div>
      <div className={classNames(styles.sTopPanel, 'row')}>
        <Button additionalStyles={styles.sFilterButton}>Filters</Button>
      </div>

      <div className={classNames(styles.sMiddle, 'row')}>
        { content }
      </div>

      <div className="row">
        <div className="col-12">
          <div className={styles.sPaginBut}>
            <ParsersPaginationComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
