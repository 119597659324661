import React from 'react';
import classNames from 'classnames';
import skeletonString2 from 'assets/imgs/skeleton-empty-double-string2.svg';
import skeletonImgSm from 'assets/imgs/skeleton-empty-img-sm.svg';
import styles from './styles.module.scss';

const SmBlock = ({ size }) => {
  const innerElems = [];
  const config = {
    DESKTOP: 4,
    MOBILE: 12,
    WIDE: 2,
  };

  for (let i = 0; i < 4; i += 1) {
    innerElems.push(
      <div className={styles.sBlockSm} key={`skelMd_${Math.random().toFixed(6)}`}>
        <img alt="loading" src={skeletonImgSm} className={styles.sBlockFrameSm} />
        <img alt="loading" src={skeletonString2} className={styles.sBlockStringsSm} />
      </div>,
    );
  }

  return (
    <div className={classNames(styles.sBContSm, `col-${config[size]}`)}>
      {innerElems}
    </div>
  );
};

export default SmBlock;
