import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const LoadingComponent = ({ show, text }) => (
  <div className={classNames(styles.container, { [styles.hidden]: !show })}>
    <div className={styles.loaderElement}>
      <div className={styles.wheel} />
      <div className={styles.text}>
        {text}
      </div>
    </div>
  </div>
);

export default LoadingComponent;
