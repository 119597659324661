export default {
  'login.button': 'Login with Google',

  'header.lastUpdate': 'Last update',
  'header.logout': 'Logout account',
  'header.parsersFilter': 'Sources',
  'header.category': 'Category',

  'filters.filter': 'Filter',
  'filters.source': 'Source',
  'filters.allSource': 'All Source',
  'filters.last': 'Last ',
  'filters.min': ' min',
  'filters.hour': ' hour',
  'filters.hours': ' hours',
  'filters.days': ' days',
  'filters.day': ' day',

  'sortingPostsFilter.title': 'Filter',
  'sortingPostsFilter.min': ' min',
  'sortingPostsFilter.hour': ' hour',
  'sortingPostsFilter.hours': ' hours',
  'sortingPostsFilter.day': ' day',
  'sortingPostsFilter.days': ' days',
  'sortingPostsFilter.months': ' months',
  'sortingPostsFilter.years': ' years',
  'sortingPostsFilter.applyButton': 'Apply',

  'sortingPostsFilter.sortByTitle': 'Sort by',
  'sortingPostsFilter.publicationsForTimeTitle': 'Publications for time',
  'sortingPostsFilter.likes': 'Likes',
  'sortingPostsFilter.comments': 'Comments',
  'sortingPostsFilter.repost': 'Repost',

  'socialWidget.seeAll': 'See all',
  'socialWidget.updated': 'The {parserName} has been updated',

  loading: 'Loading...',
  'select.all': 'Select all',
  'add.source': 'Add custom subparser',
  'add.source.btn': 'Add custom',
  'source.field.name': 'Subparser name',
  'source.field.name.placeholder': 'Enter parser name',
  'source.field.parser': 'Main parser',
  'source.field.parser.placeholder': 'Select main parser',
  'source.field.geo.placeholder': 'Select geo',
  'source.field.hl.placeholder': 'Select language',
  'source.field.property.placeholder': 'Select property',
  'source.field.keyword.placeholder': 'Enter keyword',
  'source.field.redditurl.placeholder': 'Enter parser link',
  'source.field.trendingurl.placeholder': 'Enter trending link',
  'source.field.pandaurl.placeholder': 'Enter bored panda category',
  'source.field.domain.placeholder': 'Enter clear domain',
  'source.field.facebookid.placeholder': 'Enter Facebook list',
  'source.field.googleid.placeholder': 'Enter link',
  'source.field.categoryurl.placeholder': 'Enter category URL',
  'source.field.categorylink.placeholder': 'Enter category URL',
  'source.field.twitterid.placeholder': 'Enter Twitter ID',
  'source.field.channelid.placeholder': 'Enter Channel ID',
  'source.field.country.placeholder': 'Select country',
  'source.field.newssource.placeholder': 'Select news source',
  'source.field.category.placeholder': 'Category name',
  'source.field.tag.placeholder': 'Tag name',
  'validation.required': 'Field is required',
  'validation.url': 'Invalid URL',
  'validation.wrongSourceName': 'Wrong source name format',
  'btn.save': 'Save',
  'btn.cancel': 'Cancel',
};
