import React from 'react';
import styles from './styles.module.scss';

const BreadCrumpComponent = ({ pathRoute }) => (
  <div className={styles.breadCrumbElement}>
    <div className={styles.crumbLink}>{` / ${decodeURIComponent(pathRoute)}`}</div>
  </div>
);

export default BreadCrumpComponent;
