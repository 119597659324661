import React from 'react';
import classNames from 'classnames';
import Overlay from 'components/Overlay';
import Button from 'components/Button';
import styles from './styles.module.scss';

const DeleteSourceComponent = (props) => {
  const {
    show = false,
    cancelHandler = null,
    deleteHandler = null,
    sourceName = 'noname',
    waiting,
    error,
  } = props;

  const stopHandler = (e) => {
    e.stopPropagation();
  };

  const deleteClkHandler = () => {
    deleteHandler(sourceName);
  };

  return (

    <Overlay show={show} clickHandler={() => { cancelHandler(); }}>
      <div
        role="button"
        tabIndex="0"
        className={classNames(styles.mainFrame, { [styles.showFrame]: show })}
        onClick={stopHandler}
        onKeyDown={stopHandler}
      >
        <div className={classNames({ [styles.screenHide]: error })}>
          <div className={classNames(styles.titleCont)}>
            {waiting ? 'Please wait ...' : 'Are you sure you want to delete?'}
          </div>
          <div className={
            classNames(styles.templateNameCont,
              { [styles.nameNotVisible]: waiting })
}
          >
            {sourceName}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              disabled={waiting}
              onClick={() => { cancelHandler(); }}
              additionalStyles={
                classNames(styles.buttons,
                  styles.cancelButton,
                  { [styles.disabledButton]: waiting })
              }
            >
              Cancel
            </Button>
            <Button
              additionalStyles={classNames(styles.buttons, { [styles.disabledButton]: waiting })}
              disabled={waiting}
              onClick={deleteClkHandler}
            >
              Delete
            </Button>
          </div>
        </div>

        <div className={classNames(styles.errorScreen, { [styles.screenHide]: !error })}>
          {error}
        </div>

      </div>
    </Overlay>

  );
};

export default DeleteSourceComponent;
