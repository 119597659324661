import React from 'react';
import Header from 'components/Header';
import classNames from 'classnames';
import Footer from 'components/Footer';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import styles from './styles.module.scss';

const Error404 = () => (
  <div className={styles.ErrCont}>
    <Helmet>
      <title>Error 404</title>
    </Helmet>
    <Header />
    <div className={classNames(styles.errPageContentCont, 'row')}>
      <div className={styles.errContainer}>
        <div className={styles.errorContent}>
          <div className={styles.errorName}>404</div>
          <div className={styles.errorDescr}>PAGE NOT FOUND!</div>
        </div>
        <div className={styles.errorLinks}>
          <button type="button" className={styles.errBtn}>
            <Link to="/">
              <span className={styles.backToMainLink}>BACK TO HOME</span>
            </Link>
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default Error404;
