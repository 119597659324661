import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

const TimeFilterItem = (props) => {
  const { message, clkHandler } = props;
  const handler = () => {
    clkHandler(message);
  };

  return (
    <div className={styles.timeFilterItemCont} role="button" tabIndex="0" onClick={handler} onKeyDown={handler}>
      <span>
        <FormattedMessage id="filters.last" />
        {message.value}
        <FormattedMessage id={`filters.${message.type}`} />
      </span>
    </div>
  );
};

export default TimeFilterItem;
