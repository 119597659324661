import React from 'react';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { TYPE1 } from '../ItemTypes';

const Square = ({
  children, dropFunc, hover, cols, useHandleChildScroll = false,
}) => {
  const [, drop] = useDrop({
    accept: TYPE1,
    drop: (item) => dropFunc(item),
    hover: (item) => hover(item),
  });

  let widthVal;
  let heightVal;

  if (cols === 2) {
    widthVal = 450;
    heightVal = 405;
  } else {
    widthVal = 250;
    heightVal = 364;
  }

  return (
    <div
      ref={drop}
      className={classNames(styles.squareStyle, (useHandleChildScroll && 'myBoardHandleScroll'))}
      style={{ width: widthVal, height: heightVal }}
    >
      {children}
    </div>
  );
};

export default Square;
