import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import storageService from 'services/StorageService';
import Button from 'components/Button';
import GoogleIcon from 'assets/icons/google-icon.svg';
import queryString from 'query-string';
import RequestsApi from 'services/requestsApi';
import LoadingComponent from 'components/LoadingComponent';
import styles from './styles.module.scss';

const LoginPage = (props) => {
  const [regProceed, setRegProceed] = useState(false);
  const [errorIndicatorMessage, setErrorIndicatorMessage] = useState('');
  const redirectUrl = window.location.origin + window.location.pathname;
  const { history, location } = props;

  useEffect(() => {
    if (storageService && storageService.get('accessToken')) {
      window.location.href = `${window.location.origin}/`;
    }

    const { search } = location;
    const { code, errorMessage } = queryString.parse(search);

    if (errorMessage) {
      setErrorIndicatorMessage(errorMessage);
    }

    if (!code) {
      return;
    }

    (async () => {
      setRegProceed(true);
      try {
        const res = await RequestsApi.constructor.saveAuthTokens(code, `${window.location.origin}/login`);

        if (res.data) {
          history.push('/');
        }
        if (res.response) {
          window.location.href = `${window.location.origin}/login?errorMessage=${res.response.data.message}`;
        }
      } catch (error) {
        window.location.href = `${window.location.origin}/login?errorMessage=${error.message ? error.message : 'error'}`;
      }
    })();
  }, [history, location]);

  const signIn = () => {
    const stringifiedParams = queryString.stringify({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: redirectUrl,
      scope: [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      ].join(' '),
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
    });
    window.open(`https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`, '_self');
  };

  const date = new Date();
  const footerStr = `Copyright ©  2010-${date.getFullYear()} Trendsdash.io Company S.L. All rights reserved.`;

  return (
    <div>
      <div className={classNames(styles.loginPageMain, 'row')}>
        <div
          className={classNames(styles.errorIndicator,
            { [styles.errorIndicatorShow]: errorIndicatorMessage.length })}
        >
          <div className={styles.errorIndicatorInner}>{errorIndicatorMessage}</div>
        </div>
        <div>
          <div
            className={classNames(styles.loginPageContainer,
              { [styles.loginPageContainerShow]: regProceed })}
          >
            <div className={styles.brand}>Trendsdash.io</div>
            <div className={styles.content} />
            <div className={styles.contBottom}>
              <Button onClick={signIn} icon={GoogleIcon} additionalStyles={styles.loginButton}>
                <FormattedMessage id="login.button" />
              </Button>
            </div>
          </div>
        </div>
        <LoadingComponent text="Checking registration" show={regProceed} />
        <div className={styles.footer}>
          {footerStr}
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
