import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Text from 'components/Text';
import { parserNamesWithoutImgs } from 'consts';
import styles from 'components/SocialsWidgetBlock/styles.module.scss';
import colConfig from 'components/SocialsWidgetBlock/col-sizes-config';
import ImgWithLoader from 'components/ImgWithLoader';
import BadgeContainer from 'components/BadgeContainer';
import { handleHtmlSpecSymbols } from 'helpers';
import CommentsComponent from 'components/CommentsComponent';

const SocialsWidgetBlock = (props) => {
  const {
    size = 'lg', data = {}, width, parserName, sortingPostfix = '',
  } = props;
  const ind = colConfig[width];
  let articleSection;
  if (data && data.statistic && data.statistic.articleSection) {
    articleSection = data.statistic.articleSection;
  }

  return (
    <div
      className={classNames(`col-${ind}`, 'mb-4 mb-md-0', styles.sWBouter)}

    >
      <div className={classNames(styles.sWBCont, styles[`sWBCont-${size}`])}>
        <div className={classNames({ [styles.sWBFrameCont]: !parserNamesWithoutImgs.includes(parserName) }, styles[`sWBFrameCont-${size}`])}>
          <a
            href={data.link}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
            className={styles.sWBFlink}
          >
            {parserNamesWithoutImgs.includes(parserName)
              ? (<div className={styles.breakLine} />)
              : (
                <ImgWithLoader
                  parserName={parserName}
                  imgUrl={handleHtmlSpecSymbols(data.imgUrl)}
                  imgClass={styles.sWBFrame}
                />
              )}
          </a>
          <CommentsComponent data={data} parserName={parserName} />
        </div>
        <div className={classNames(styles.sWBText, 'searching-label', styles[`sWBText-${size}`])}>

          <div className={classNames(styles.sectionStyle,
            { [styles.sectionNotVisible]: !articleSection })}
          >
            {articleSection}
          </div>

          <Link to={`/parser-data/${parserName}/${encodeURIComponent(data.category.name)}?${sortingPostfix}`}>

            <Text
              content={data.category.name}
              additionalStyles={classNames(styles.trendsStyle,
                { [styles.displayed]: data.category.name }, 'test1')}
            />
          </Link>

          <div>
            <div className={styles.descriptionPart}>
              <Text
                content={data.title || data.description || 'empty'}
              />
            </div>

            <BadgeContainer data={data} parserName={parserName} />
          </div>

        </div>

      </div>
    </div>
  );
};

export default SocialsWidgetBlock;
