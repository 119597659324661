import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import SelectedFiltersItem from '../SelectedFiltersItem';
import styles from './styles.module.scss';

const SelectedFilters = ({ listBundle = [], parsersInfo, show }) => {
  const ids = useRef(new Map());
  const names = useRef(new Map());
  const crawlerToParser = useRef(new Map());
  const allParsers = useSelector((store) => store.parsers.allParsers);

  useEffect(() => {
    if (allParsers && allParsers.length && !ids.current.size) {
      allParsers.forEach((parser) => {
        ids.current.set(parser.title, parser.id);
        names.current.set(parser.title, parser.name);
        crawlerToParser.current.set(parser.title, parser.parser);
      });
    }
  }, [allParsers]);

  let buttons = [];

  if (listBundle && listBundle.length && parsersInfo && parsersInfo.names && ids.current.size > 0) {
    const textVisible = listBundle.length < 8;

    buttons = listBundle.map((item, i) => (
      <SelectedFiltersItem
        text={item}
        href={`parser_Id_${crawlerToParser.current.get(item)}`}
        key={`selected_filters_key_${item}`}
        iconSrc={`/icons/parsers/${crawlerToParser.current.get(item)}.png`}
        bnum={i}
        textVisible={textVisible}
      />
    ));
  }

  return (
    <div className={classNames(styles.selectedFilters, { [styles.selectedFiltersHide]: !show }, 'd-none d-md-flex')}>
      {buttons}
    </div>
  );
};

export default SelectedFilters;
