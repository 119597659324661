import React from 'react';
import styles from './styles.module.scss';

const PostsSkeletonItem = () => (
  <div className={styles.container}>
    <div className={styles.screen} />
  </div>
);

export default PostsSkeletonItem;
