import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Overlay from 'components/Overlay';
import TimeFilterItem from 'components/FiltersBar/TimeFilterItem';
import timeFilterIcon from 'assets/icons/time-filter-icon.svg';
import { setTimeFilter } from 'store/reducers/page';
import { TIME_FILTER_CONFIG } from 'consts';
import { createDefaultFiltersTemplate } from 'helpers';
import { setSources } from 'store/reducers/parsers';
import { setToNoneTemplate } from 'store/reducers/templates';
import styles from './styles.module.scss';
import filterConfig from './time-filter-config';

const TimeFilterComponent = (props) => {
  const defTime = useSelector((state) => state.page.timeFilter);
  const allSources = useSelector((state) => state.parsers.allSources);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { items } = filterConfig;
  const { additionalStyle = {} } = props;

  const clkHandler = () => {
    setShow(!show);
  };

  const itemChoose = (item) => {
    dispatch(setTimeFilter(item));

    const sourceCopy = JSON.parse(JSON.stringify(allSources));

    const newSources = sourceCopy.map((source) => {
      const newSource = { ...source };
      newSource.options.period = { value: item.value, unit: TIME_FILTER_CONFIG[item.type] };
      return newSource;
    });

    const onlyMarkedSources = newSources.filter((v) => v.isMarked);
    const template = createDefaultFiltersTemplate(onlyMarkedSources);

    dispatch(setToNoneTemplate(template));
    dispatch(setSources(newSources));
    setShow(false);
  };

  const renderedItems = items.map((v) => (
    <TimeFilterItem key={`timeFilterItem_${v.value}_${v.type}`} message={v} clkHandler={itemChoose} />
  ));

  return (
    <div className={classNames(styles.tfcCont, additionalStyle, 'mb-3 mb-md-0')}>

      <div
        className={classNames(styles.tfcMainButton, { [styles.highLayer]: show }, 'py-md-2')}
        onClick={clkHandler}
        onKeyDown={clkHandler}
        role="button"
        tabIndex="0"
      >
        <img src={timeFilterIcon} alt="tf-icon" />
        <span className={styles.tfcLabel}>
          <FormattedMessage id="filters.last" />
          {defTime.value}
          <FormattedMessage id={`filters.${defTime.type}`} />
        </span>
      </div>

      <div className={classNames(styles.tfcRect, { [styles.show]: show })}>
        {renderedItems}
      </div>
      <Overlay show={show} clickHandler={(a) => setShow(a)} />
    </div>
  );
};

export default React.memo(TimeFilterComponent);
