export const TIME_FILTER_CONFIG = {
  days: 'd',
  day: 'd',
  hours: 'h',
  hour: 'h',
  min: 'min',
};

export const TEMPLATE_TIME = {
  d: 'day',
  h: 'hour',
  min: 'min',
};

export const SORTING_OPTIONS_NAME = 'sortingOptions';

export const statisticParserNameConfig = {
  twitterTrendsUSA: 'twitterTrends',
  facebookMemes: 'facebook',
  redditOutOfTheLoop: 'reddit',
  dailyDot: 'dailyDot',
  googleTrends: 'googleTrends',
  tikTok: 'tikTok',
  junkeeParser: 'junkee',
  googleNews: 'googleNews',
  melMagazineParser: 'melMagazine',
  boredPanda: 'boredPanda',
  googleAlertMemes: 'googleAlert',
  googleAlertViralVideo: 'googleAlert',
  chartsBeatCracked: 'chartBeat',
  chartsBeatEbaumsWorld: 'chartBeat',
  chartsBeatKnowyourmeme: 'chartBeat',
  chartsBeatCheezburger: 'chartBeat',
};

export const configNames = {
  twitterTrendsUSA: 'Retweets',
  tikTok: 'Shares',
  tikTokMusicUsa: 'Shares',
  tikTokChallengesUsa: 'Shares',
  facebookMemes: 'Shares',
};

export const parserNamesWithoutImgs = [
  'redditOutOfTheLoop',
  'knowYourMemeEmptySearch',
  'redditProRevenge',
  'redditMaliciousCompliance',
  'redditTalesFromTechSupport',
  'redditAskReddit',
  'redditTifu',
  'redditPettyRevenge',
];

export const DEFAULT_PARSER_ICON_LINK = '/icons/default-imgs/default-parser.png';

export const sizes = {
  XS: 1,
  SM: 2,
  MD: 3,
  LG: 4,
  XL: 5,
  WIDE: 6,
};

export const DEFAULT_SORT_OPTIONS = [
  {
    field: 'dateCreated',
    direction: 1,
  },
];

export const defaultPostImageUrl = '/icons/default-imgs/default-image.svg';

export const NOTIF_DURATION = 60000;
