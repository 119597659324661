import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Input = (props) => {
  const {
    name,
    value,
    onChange = () => {},
    placeholder = '',
    icon,
    fullWidth = false,
    colorTheme,
    type = 'text',
    prefix,
    errorMessage,
    disabled,
    delay = null,
    notBordered = false,
    autoFocus = false,
    height,
  } = props;

  const [localValue, setLocalValue] = useState('');

  let timer = useRef();

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => () => {
    clearTimeout(timer?.current);
    timer = null;
  }, []);

  const handleInputChange = (e) => {
    let { value: targetValue } = e.target;

    if (type === 'number' && targetValue) {
      // eslint-disable-next-line radix
      targetValue = parseInt(targetValue);
    }

    if (type === 'number' && Number.isNaN(targetValue)) {
      return;
    }

    const { current } = timer;

    if (current) {
      clearTimeout(timer.current);
    }

    setLocalValue(targetValue);

    if (!delay) {
      onChange(name, targetValue);
      return;
    }

    timer.current = setTimeout(() => {
      onChange(name, targetValue);
    }, delay);
  };

  const inputProps = {
    className: styles.input,
    name,
    placeholder,
    value: localValue,
    disabled,
    autoFocus,
    onChange: handleInputChange,
  };

  const isTextArea = type === 'textarea';
  const InputTag = isTextArea ? 'textarea' : 'input';

  if (!isTextArea) {
    inputProps.type = type;
  }

  if (type === 'number') {
    inputProps.min = 1;
    inputProps.step = 1;
  }

  return (
    <>
      <label
        htmlFor={name}
        className={classNames(
          styles.inputWrapper,
          {
            [styles.withIcon]: icon,
            [styles.fullWidth]: fullWidth,
            [styles.withPrefix]: prefix,
            [styles.textarea]: isTextArea,
            [styles.error]: errorMessage,
            [styles.disabled]: disabled,
            [styles.notBordered]: notBordered,
          },
          styles[colorTheme],
        )}
      >
        { prefix && <span className={styles.prefix}>{prefix}</span> }
        { icon && (<span className={styles.inputIcon}>{icon}</span>) }
        <InputTag {...inputProps} autoComplete="off" style={{ height }} />
      </label>
      {errorMessage && <div className={styles.errorText}>{errorMessage}</div>}
    </>
  );
};

export default Input;
