import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import SocialsWidget from 'components/SocialsWidget';
import ParserUpdaterComponent from 'components/ParserUpdaterComponent';
import SortingPostsFilter from 'components/SortingPostsFilter';
import Search from 'components/FilterAndSortingBar/Search';
import { sizes } from 'consts';
import { onImageError, createDefaultFiltersTemplate } from 'helpers';
import { setToNoneTemplate } from 'store/reducers/templates';
import { setSourcesOptions } from 'store/reducers/parsers';
import styles from './styles.module.scss';

const ParserWidget = (props) => {
  const { parser } = props;
  const pageSize = useSelector((store) => store.page.pageSize);
  const { allSources, allParsers } = useSelector((st) => st.parsers);
  const [iconAddr, setIconAddr] = useState(`/icons/parsers/${parser.parser}.png`);
  const [sources, setSources] = useState([]);
  const [searchedSources, setSearchedSources] = useState([]);
  const [crawlerToParser, setCrawlerToParser] = useState(null);
  const [activeSource, setActiveSource] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const refSelect = useRef(null);
  const dispatch = useDispatch();

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const clearClick = () => {
    setSearchValue('');
  };

  const dontLoadedImg = () => {
    setIconAddr('/icons/defaultRepl-icon.svg');
  };

  const onSourceUpdate = useCallback(() => {
    activeSource.dateUpdated = new Date();
    dispatch(setSourcesOptions(activeSource));
  }, [dispatch, activeSource]);

  const onOpenSelect = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onSelectSource = (id) => {
    const selectedSource = sources.find((s) => s.id === id);

    if (selectedSource) {
      setActiveSource(selectedSource);
      setOpen(false);
    }
  };

  const filterPosts = useCallback((obtainedOptions) => {
    activeSource.options = obtainedOptions;
    const newSources = JSON.parse(JSON.stringify(allSources));
    const onlyMarkedSources = newSources.filter((p) => p.isMarked);
    const template = createDefaultFiltersTemplate(onlyMarkedSources);

    dispatch(setToNoneTemplate(template));
    dispatch(setSourcesOptions(activeSource));
  }, [activeSource, allSources]);

  const onChangeSource = useCallback((direction = 'left') => {
    const currentIndex = sources.map((s) => s.id).indexOf(activeSource.id);
    let newIndex = 0;

    if (direction === 'left') {
      if (currentIndex <= 0) {
        return;
      }

      newIndex = currentIndex - 1;
    } else {
      if (currentIndex >= sources.length - 1) {
        return;
      }

      newIndex = currentIndex + 1;
    }

    setActiveSource(sources[newIndex]);
  }, [sources, activeSource]);

  const isFirst = useCallback(
    () => sources.map((s) => s.id).indexOf(activeSource.id) === 0,
    [sources, activeSource],
  );

  const isLast = useCallback(
    () => sources.map((s) => s.id).indexOf(activeSource.id) === sources.length - 1,
    [sources, activeSource],
  );

  useEffect(() => {
    const filteredByParser = allSources
      .filter((source) => source.isMarked && source.parserId === parser.id);

    if (filteredByParser.length) {
      setActiveSource(filteredByParser[0]);
    }

    const newCrawlerToParser = new Map();
    allSources.forEach((source) => {
      const foundParser = allParsers.find((parserItem) => parserItem.id === source.parserId);

      if (foundParser) {
        newCrawlerToParser.set(source.title, foundParser.parser);
      }
    });
    setCrawlerToParser(newCrawlerToParser);
    setSources(filteredByParser);
  }, [allSources, allParsers]);

  useEffect(() => {
    let filteredList = sources;

    if (searchValue) {
      filteredList = sources
        .filter(({ title }) => title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
    }

    setSearchedSources(filteredList);
  }, [sources, searchValue]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && refSelect.current && !refSelect.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  const activeLink = useMemo(() => {
    if (!activeSource) {
      return '';
    }

    const linkForSeeAll = `/parser-data/${activeSource.name}`;
    const sortingPostfix = `sortby_field=${activeSource.options.sortBy.field}&sortby_direction=${activeSource.options.sortBy.dir}&period_type=${activeSource.options.period.unit}&period_value=${activeSource.options.period.value}`;
    return `${linkForSeeAll}?${sortingPostfix}`;
  }, [activeSource]);

  return (
    <div className={classNames(styles.sWCont)} ref={refSelect}>
      <div className={classNames(styles.sWTopPanel, 'row')}>
        <div className={styles.sWTopPanelLeft}>
          <Link to={activeLink} className={styles.leftImgLink}>
            <img
              src={iconAddr}
              alt="icon-pars"
              onError={dontLoadedImg}
            />
            <span
              className={styles.sWSocialName}
              style={{ color: parser.color }}
            >
              {parser.title}
            </span>
          </Link>
          <div className={styles.navigationWrapper}>
            <button
              type="button"
              className={classNames(styles.navigationBtn, {
                [styles.disabledBtn]: !sources.length || isFirst(),
              })}
              onClick={() => onChangeSource()}
            >
              <img
                src="/icons/arrowLeft.svg"
                alt="icon-nav"
              />
            </button>
            <button type="button" className={styles.navigationSearchBtn} onClick={onOpenSelect}>
              <span className={styles.navigationSearchText}>
                {activeSource ? activeSource.title : null}
              </span>
              <img
                src="/icons/arrowDown.svg"
                alt="icon-open"
              />
            </button>
            <button
              type="button"
              className={classNames(styles.navigationBtn, {
                [styles.disabledBtn]: !sources.length || isLast(),
              })}
              onClick={() => onChangeSource('right')}
            >
              <img
                src="/icons/arrowRight.svg"
                alt="icon-nav"
              />
            </button>
            {open ? (
              <div className={styles.navigationDropdown}>
                <Search
                  onSearchChange={onSearchChange}
                  clearClick={clearClick}
                  searchValue={searchValue}
                  stylesContainer={styles.searchContainer}
                />
                <div className={styles.sourceTagWrapper}>
                  {searchedSources.map((source) => (
                    <div
                      key={`sourceTag_${source.id}`}
                      className={classNames(styles.sourceTag, {
                        [styles.sourceTagActive]: activeSource.id === source.id,
                      })}
                      onClick={() => onSelectSource(source.id)}
                    >
                      <div className={styles.sourceTagIcon}>
                        <img src={`/icons/parsers/${crawlerToParser.get(source.title)}.png`} alt="parser-icon" onError={(e) => onImageError(e, 17)} />
                      </div>
                      <div className={styles.sourceTagName}>
                        {source.title}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {activeSource ? (
          <>
            <SortingPostsFilter
              parserInfo={activeSource}
              filterPosts={filterPosts}
            />
            <div className={classNames({ [styles.toLeft]: pageSize < sizes.XS })}>
              <ParserUpdaterComponent parser={activeSource} onUpdate={onSourceUpdate} />
            </div>
          </>
        ) : null}
      </div>
      {activeSource ? (
        <SocialsWidget
          parserType={parser.parser}
          source={activeSource}
          key={`key_for_social_widget_${activeSource.title}`}
        />
      ) : null}
    </div>
  );
};
export default ParserWidget;
