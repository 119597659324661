import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const date = new Date();
const footerStr = `Copyright ©  2010-${date.getFullYear()} Trendsdash.io Company S.L. All rights reserved.`;

const Footer = () => (
  <div className={classNames(styles.mainPageFooterCont, 'row')}>
    <div className={classNames(styles.mainPageFooter, 'col-12 col-xl-10 justify-content-center justify-content-md-between')}>
      <Link to="/" className={classNames(styles.mainPageFooterLogo, 'd-none d-md-block')}>Trendsdash.io</Link>
      <div className={styles.mainPageFooterCpr}>
        {footerStr}
      </div>
    </div>
  </div>
);

export default React.memo(Footer);
