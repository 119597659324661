import React from 'react';
import classNames from 'classnames';
import PostsSkeletonItem from './PostsSkeletonItem';
import styles from './styles.module.scss';

const PostsSkeleton = () => {
  const items = [];

  for (let i = 0; i < 10; i += 1) {
    items.push(
      <PostsSkeletonItem
        key={`posts-skeleton-${Math.random().toFixed(6)}`}
      />,
    );
  }

  return (
    <div className={classNames('col-12 col-md-10 col-lg-8 p-0')}>
      <div className={styles.fakePanel} />
      {items}
    </div>
  );
};

export default PostsSkeleton;
