import store from 'store/store';
import {
  readTemplate,
  setModelParsers,
  setModelSources,
  setParsers,
  setSources,
} from 'store/reducers/parsers';
import { setTimeFilter } from 'store/reducers/page';
import { createDefaultFiltersTemplate, templateDateConvert } from 'helpers';
import { setAllTemplates, setCurrentTemplateIndex } from 'store/reducers/templates';
import storageService from 'services/StorageService';
import RequestsApi from 'services/requestsApi';

const appInit = (sId) => (async (sourceId) => {
  // parser
  const parserRes = await RequestsApi.authRequest(`${process.env.REACT_APP_API_BASE_URL}/parser`, 'get');
  const parserItems = parserRes.data;

  store.dispatch(setModelParsers(parserItems));

  // sources
  const res = await RequestsApi.authRequest(`${process.env.REACT_APP_API_BASE_URL}/source`, 'get');
  const sourceItems = res.data;
  const markedSources = sourceItems.map((v) => {
    const sortBy = { ...v.sortingOptions.default[0] };
    const cParser = { ...v };

    cParser.isMarked = true;
    cParser.options = {
      sortBy,
      period: { unit: 'h', value: 6 },
    };
    return cParser;
  });

  store.dispatch(setModelSources(markedSources));

  const templRes = await RequestsApi.getTemplates();
  let allTemplates;
  let index = 0;

  if (templRes.data && templRes.data.length) {
    allTemplates = templRes.data;
    index = templRes.data.length - 1;
    const currentTemplateName = storageService.get('currentTemplateName');
    allTemplates.reverse();
    if (currentTemplateName) {
      allTemplates.forEach((template, ind) => {
        if (template.name === currentTemplateName) {
          index = ind;
        }
      });
    }

    if (sourceId) {
      const currentTemplate = allTemplates[index];
      let templSources = currentTemplate.crawlers.map((ct) => ct.crawlerId);
      templSources = [...templSources, sourceId];
      const newSources = markedSources.filter((source) => templSources.includes(source.id));
      const newTemplate = createDefaultFiltersTemplate(newSources);
      allTemplates = [newTemplate, ...allTemplates];
      allTemplates.reverse();
      index = allTemplates.length - 1;
    }
  } else {
    const defTemplate = createDefaultFiltersTemplate(markedSources);
    allTemplates = [defTemplate];
  }

  const allSourcesWithPeriod = sourceItems.map((v) => {
    const cParser = { ...v };
    cParser.options = {
      sortBy: { field: 'datePublished', dir: '-1' },
      period: { ...allTemplates[index].global.period },
    };

    return cParser;
  });

  store.dispatch(setParsers(parserItems));
  store.dispatch(setSources(allSourcesWithPeriod));
  store.dispatch(setTimeFilter(templateDateConvert(allTemplates[index].global.period)));
  store.dispatch(setAllTemplates(allTemplates));
  store.dispatch(setCurrentTemplateIndex(index));
  store.dispatch(readTemplate(allTemplates[index]));

  return true;
})(sId);

export default appInit;
