export default {
  items: [
    { value: 30, type: 'min' },
    { value: 1, type: 'hour' },
    { value: 2, type: 'hours' },
    { value: 6, type: 'hours' },
    { value: 12, type: 'hours' },
    { value: 1, type: 'day' },
    { value: 3, type: 'days' },
    { value: 7, type: 'days' },
  ],
};
