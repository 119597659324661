import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Search from '../Search';
import FilterParserItem from './Item';
import styles from './styles.module.scss';

const ParserBlock = (props) => {
  const {
    selectedId,
    onChange,
  } = props;
  const [searchValue, setSearchValue] = useState('');

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const clearClick = () => {
    setSearchValue('');
  };

  const allParsers = useSelector((store) => store.parsers.allParsers);
  const sortedParsers = useMemo(() => [...allParsers].sort((a, b) => {
    if (a.title < b.title) { return -1; }
    if (a.title > b.title) { return 1; }
    return 0;
  }), [allParsers]);

  const withSelected = sortedParsers.map((item) => {
    const selected = item.id === selectedId;

    return {
      ...item,
      selected,
    };
  });

  let filteredList = withSelected;

  if (searchValue) {
    filteredList = withSelected
      .filter(({ title }) => title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  }

  return (
    <div className={styles.widgetMainContainer}>
      <div className={styles.containerTitle}>
        <div className={styles.titleStyle}>
          <FormattedMessage id="header.category" />
        </div>
      </div>
      <div className={styles.borderContainer}>
        <div className={styles.itemsContainerWrapper}>
          <Search
            onSearchChange={onSearchChange}
            clearClick={clearClick}
            searchValue={searchValue}
          />
          <div className={styles.listContainer}>
            {filteredList.map((item) => (
              <FilterParserItem key={`filterItem_${item.id}`} item={item} onChange={onChange} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParserBlock;
