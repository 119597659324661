import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from 'store/reducers/modal';
import Modal from '../modal';
import CommentModal from '../CommentsComponent/CommentModal';
import styles from '../CommentsComponent/styles.module.scss';

export default function ModalWrapper() {
  const modalContent = useSelector((store) => store.modal).content;
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };
  return (modalContent && (
  <Modal open onClose={onClose}>
    <button
      type="button"
      className={styles.closeButton}
      onClick={() => {
        dispatch(closeModal());
      }}
    >
      <img
        alt="badge_altimg"
        src="/icons/modal/close-modal-icon.svg"
      />
    </button>
    <CommentModal data={modalContent.data} parserName={modalContent.parserName} />
  </Modal>
  )) || null;
}
