const localStorageStorageSource = {
  name: 'localStorageStorageSource',

  get(vname) {
    const val = localStorage.getItem(vname);
    if (val) return JSON.parse(val);
    return null;
  },
  add(vname, value) {
    if (value) {
      localStorage.setItem(vname, JSON.stringify(value));
    }
  },
  delete(vname) {
    localStorage.removeItem(vname);
  },
  clear() {
    localStorage.clear();
  },
};

export default localStorageStorageSource;
