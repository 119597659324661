import React from 'react';
import ReactDOM from 'react-dom';
import './scss/base.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import storageService from 'services/StorageService';
import requestsApi from 'services/requestsApi';
import store from './store/store';
import App from './App';

// This strings below must be deleted on the production mode!
window.storageService = storageService;
window.store = store;
window.requestsApi = requestsApi;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,

  document.getElementById('root'),
);
