/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  Fragment,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import Overlay from 'components/Overlay';
import Button from 'components/Button';
import Select from 'components/Fields/Select';
import RenderParserOption from 'components/ParserOption';
import RequestsApi from 'services/requestsApi';
import { urlPattern } from 'helpers';
import styles from './styles.module.scss';
import appInit from '../../services/appInit';
import { setLastCustomSource } from '../../store/reducers/parsers';

const ErrorMessageComponent = ({ message }) => (
  <span className={styles.errorMessage}>{message}</span>
);

const parsersWithoutSubName = ['facebookDashboard'];

const updateRequestDataByParser = (parserObj = {}, data = {}) => {
  if (parserObj.parser === 'facebookDashboard') {
    const facebookIdProperty = parserObj.properties.find(({ name }) => name === 'facebookId');
    const option = facebookIdProperty.options.find(({ value }) => value === data.facebookId);
    return {
      ...data,
      name: option.name,
    };
  }

  return data;
};

const AddSourceModal = (props) => {
  const {
    show = false,
    cancelHandler,
    selectedParser = '',
  } = props;
  const els = useRef(null);
  const timeout = useRef();
  const intl = useIntl();
  const dispatch = useDispatch();
  const allParsers = useSelector((store) => store.parsers.allParsers);
  const allowedParsers = useMemo(() => allParsers.filter(
    (parser) => parser.properties.some((prop) => Object.keys(prop).length),
  ), [allParsers]);
  const [currentParser, setCurrentParser] = useState(null);
  const [mainError, setMainError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const allowedValidations = {
    required: {
      required: intl.formatMessage({ id: 'validation.required' }),
    },
    url: {
      pattern: {
        value: urlPattern,
        message: intl.formatMessage({ id: 'validation.url' }),
      },
    },
  };

  const {
    register,
    unregister,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    control,
    reset,
    getValues,
  } = useForm();

  const getParserById = useCallback(
    (id) => allowedParsers.find((item) => item.id === id),
    [allowedParsers],
  );

  const { parser: activeParserId } = getValues();
  const activeParser = getParserById(activeParserId);

  useEffect(() => {
    const parser = getParserById(selectedParser);
    setCurrentParser(parser);
  }, [selectedParser]);

  const resetModalData = () => {
    const parser = allowedParsers.find((item) => item.id === selectedParser);
    setCurrentParser(parser);
    reset();
    setValue('parser', selectedParser || '');

    clearErrors();
    setMainError(null);
  };

  useEffect(() => {
    reset();
    if (currentParser) {
      clearErrors();
      setMainError(null);
    }
    setValue('parser', (currentParser && currentParser.id) || '');
  }, [currentParser]);

  const onSubmit = (data) => {
    (async () => {
      setBtnLoading(true);
      try {
        const parserObj = getParserById(data.parser);
        const updatedData = updateRequestDataByParser(parserObj, data);
        const res = await RequestsApi.createSource({ ...updatedData });
        const newSourceId = res?.data?.id;
        if (newSourceId) {
          await appInit(newSourceId).finally(() => {
            dispatch(setLastCustomSource(res.data));
          });
        }
        resetModalData();
        cancelHandler();
      } catch (err) {
        if (err.response) {
          setMainError(err.response.data.message);
        } else {
          setMainError(err.message);
        }
      }

      setBtnLoading(false);
    })();
  };
  const beforeSubmit = useCallback(() => {
    if (!currentParser) {
      setError('parser', {
        message: intl.formatMessage({ id: 'validation.required' }),
      });
      return;
    }

    handleSubmit(onSubmit)();
  }, [currentParser]);

  const stopHandler = (e) => {
    e.stopPropagation();
  };

  const stopHandlerForKeys = (e) => {
    if (e.key === 'Escape') {
      resetModalData();
      cancelHandler();
    }

    e.stopPropagation();
  };

  const changeFieldsByParser = useCallback((event) => {
    const { value } = event.target;
    const parser = allowedParsers.find((item) => item.id === value);
    unregister();
    setCurrentParser(parser);
    clearErrors('parser');
    setValue('parser', value);
  }, [allowedParsers]);

  register('parser', {
    onChange: changeFieldsByParser,
  });

  useEffect(() => {
    const parser = allowedParsers.find((item) => item.id === selectedParser);
    unregister();
    setCurrentParser(parser);
    setLoading(false);
  }, [allowedParsers]);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (els.current && show) {
      (() => {
        const inputStr = els.current;
        timeout.current = setTimeout(() => {
          setLoading(false);
          inputStr.focus();
        }, 500);
      })();
    }
  }, [show]);

  const renderFields = useCallback(() => {
    if (!currentParser || !currentParser.properties) {
      return null;
    }

    return (
      <div className={styles.fieldContainer}>
        {currentParser.properties.map((property) => {
          let validations = {};
          property.validation.forEach((valid) => {
            if (allowedValidations[valid]) {
              validations = {
                ...validations,
                ...allowedValidations[valid],
              };
            }
          });

          const propId = property.name;
          let fieldElement = null;
          let example = null;

          switch (property.type) {
            case 'input':
              fieldElement = (
                <input
                  type="text"
                  id={propId}
                  className={classNames(styles.inputElem, {
                    [styles.inputElemNotValid]: !!errors && errors[propId],
                  })}
                  placeholder={intl.formatMessage({ id: `source.field.${property.name.toLowerCase()}.placeholder` })}
                  {...register(propId, validations)}
                />
              );
              if (property.description) {
                example = (
                  <span className={styles.inputExample}>{property.description}</span>
                );
              }
              break;
            case 'select': {
              fieldElement = (
                <Controller
                  name={propId}
                  control={control}
                  rules={validations}
                  render={({ field }) => {
                    const {
                      onChange,
                      onBlur,
                      value,
                      name,
                    } = field;
                    return (
                      <Select
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        canScroll
                        placeholder={intl.formatMessage({ id: `source.field.${property.name.toLowerCase()}.placeholder` })}
                        fullWidth
                        options={property.options}
                        registered
                        errorMessage={errors && errors[propId] ? errors[propId].message : null}
                        withSearch={property.options && property.options.length > 10}
                      />
                    );
                  }}
                />
              );
              break;
            }
            default:
              break;
          }

          return (
            <Fragment key={property.name}>
              <label htmlFor={propId} className={styles.fieldLabel}>
                {property.title}
              </label>
              {fieldElement}
              {example}
              {errors && errors[propId] && property.type !== 'select'
                ? (<ErrorMessageComponent message={errors[propId].message} />)
                : null}
            </Fragment>
          );
        })}
      </div>
    );
  },
  [currentParser, errors]);

  return (
    <Overlay show={show} clickHandler={() => {}}>
      <div
        role="button"
        tabIndex="0"
        className={classNames(styles.mainFrame, { [styles.showFrame]: show })}
        onClick={(e) => stopHandler(e)}
        onKeyDown={(e) => stopHandlerForKeys(e)}
      >
        {loading ? (
          <div><FormattedMessage id="loading" /></div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.contLine}>
              <div className={styles.titleCont}>
                <FormattedMessage id="add.source" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="chooseParser" className={styles.fieldLabel}>
                  <FormattedMessage id="source.field.parser" />
                </label>
                <Controller
                  name="parser"
                  control={control}
                  rules={{ required: intl.formatMessage({ id: 'validation.required' }) }}
                  render={({ field }) => {
                    const {
                      onChange,
                      onBlur,
                      value,
                      name,
                    } = field;
                    return (
                      <Select
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        canScroll
                        renderOption={(data, intlEntity, chosenItems) => (
                          <RenderParserOption {...{ data, chosenItems }} />
                        )}
                        placeholder={intl.formatMessage({ id: 'source.field.parser.placeholder' })}
                        fullWidth
                        options={allowedParsers.map(({ id, parser, title }) => ({
                          imgSrc: `/icons/parsers/${parser}.png`,
                          name: title,
                          value: id,
                        }))}
                        registered
                        errorMessage={errors && errors.parser ? errors.parser.message : null}
                      />
                    );
                  }}
                />
              </div>
              {renderFields()}
              { !parsersWithoutSubName.includes(activeParser?.parser) && (
                <div className={styles.fieldContainer}>
                  <label htmlFor="parserName" className={styles.fieldLabel}>
                    <FormattedMessage id="source.field.name" />
                  </label>
                  <input
                    id="parserName"
                    type="text"
                    placeholder={intl.formatMessage({ id: 'source.field.name.placeholder' })}
                    spellCheck={false}
                    className={classNames(styles.inputElem, {
                      [styles.inputElemNotValid]: !!errors && errors.name,
                    })}
                    {...register('name', {
                      required: intl.formatMessage({ id: 'validation.required' }),
                      pattern: {
                        value: /\w+/,
                        message: intl.formatMessage({ id: 'validation.wrongSourceName' }),
                      },
                    })}
                  />
                  {errors && errors.name
                    ? (<ErrorMessageComponent message={errors.name.message} />)
                    : null}
                </div>
              ) }
              <div className={styles.buttonContainer}>
                <Button
                  onClick={() => { resetModalData(); cancelHandler(); }}
                  additionalStyles={
                    classNames(styles.buttons, styles.cancelButton)
                  }
                >
                  <FormattedMessage id="btn.cancel" />
                </Button>
                <Button
                  disabled={btnLoading}
                  onClick={beforeSubmit}
                  additionalStyles={classNames(styles.buttons, styles.addButton, {
                    [styles.disabledButton]: btnLoading,
                  })}
                >
                  <FormattedMessage id={btnLoading ? 'loading' : 'btn.save'} />
                </Button>
              </div>
            </div>
            <div className={classNames(styles.errorScreen)}>
              {mainError}
            </div>
          </form>
        )}
      </div>
    </Overlay>
  );
};

export default AddSourceModal;
