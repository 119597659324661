import storageService from 'services/StorageService';

export const SET_ALL_TEMPLATES = 'SET_ALL_TEMPLATES';
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';
export const SET_CURRENT_TEMPLATE_INDEX = 'SET_CURRENT_TEMPLATE_INDEX';
export const SET_TO_NONE_TEMPLATE = 'SET_TO_NONE_TEMPLATE';
export const ADD_NEW_TEMPLATE = 'ADD_NEW_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const CLEAR_SOURCE_FROM_TEMPLATE = 'CLEAR_SOURCE_FROM_TEMPLATE';

const initialState = {
  allTemplates: [],
  currentTemplate: null,
  isRedacted: false,
  currentTemplateIndex: -1,
};

export const deleteParserTemplate = (data) => ({
  type: DELETE_TEMPLATE,
  data,
});

export const addNewTemplate = (data) => ({
  type: ADD_NEW_TEMPLATE,
  data,
});

export const setToNoneTemplate = (data) => ({
  type: SET_TO_NONE_TEMPLATE,
  data,
});

export const setAllTemplates = (data) => ({
  type: SET_ALL_TEMPLATES,
  data,
});

export const setCurrentTemplate = (data) => ({
  type: SET_CURRENT_TEMPLATE,
  data,
});

export const setCurrentTemplateIndex = (data) => ({
  type: SET_CURRENT_TEMPLATE_INDEX,
  data,
});

export const clearSourceFromTemplate = (data) => ({
  type: CLEAR_SOURCE_FROM_TEMPLATE,
  data,
});

const templates = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_TEMPLATES: {
      let curIndex = -1;
      let curTemplate = null;

      if (action.data.length > 0) {
        curIndex = action.data.length - 1;
        curTemplate = action.data[curIndex];
      }

      return {
        ...state,
        currentTemplateIndex: curIndex,
        allTemplates: action.data,
        currentTemplate: curTemplate,
      };
    }
    case SET_CURRENT_TEMPLATE: {
      return { ...state, currentTemplate: action.data };
    }
    case SET_CURRENT_TEMPLATE_INDEX: {
      const index = action.data;
      const currentTemplate = state.allTemplates[index];
      return { ...state, currentTemplate, currentTemplateIndex: index };
    }
    case SET_TO_NONE_TEMPLATE: {
      const currentTemplate = action.data;
      let newTemplates = [...state.allTemplates];

      if (newTemplates[newTemplates.length - 1].name === 'none') {
        newTemplates[newTemplates.length - 1] = currentTemplate;
      } else {
        newTemplates = [...newTemplates, currentTemplate];
      }

      return {
        ...state,
        currentTemplate,
        allTemplates: newTemplates,
        currentTemplateIndex: 0,
      };
    }

    case ADD_NEW_TEMPLATE: {
      const newCurrentTemplate = action.data;
      const newTemplates = [...state.allTemplates];
      if (newTemplates.length > 0 && newTemplates[newTemplates.length - 1].name === 'none') {
        newTemplates.pop();
      }

      newTemplates.push(newCurrentTemplate);
      const newCurrentTemplateIndex = newTemplates.length - 1;

      return {
        ...state,
        allTemplates: newTemplates,
        currentTemplate: newCurrentTemplate,
        currentTemplateIndex: newCurrentTemplateIndex,
      };
    }

    case DELETE_TEMPLATE: {
      const newTemplates = [...state.allTemplates];
      const templateId = action.data;

      const filteredTemplates = newTemplates
        .filter((template) => !(template.id === templateId));

      const newCurrentTemplateIndex = filteredTemplates.length - 1;
      const newCurrentTemplate = filteredTemplates[newCurrentTemplateIndex];
      storageService.add('currentTemplateName', newCurrentTemplate.name);

      return {
        ...state,
        allTemplates: filteredTemplates,
        currentTemplate: newCurrentTemplate,
        currentTemplateIndex: newCurrentTemplateIndex,
      };
    }

    case CLEAR_SOURCE_FROM_TEMPLATE: {
      if (!state.currentTemplate) {
        return state;
      }

      const idToRemove = action.data;
      const newCrawlers = state.currentTemplate.crawlers.filter(
        ({ crawlerId }) => crawlerId !== idToRemove,
      );
      const newCurrentTemplate = {
        ...state.currentTemplate,
        crawlers: newCrawlers,
      };
      const newTemplates = [...state.allTemplates];
      const withRemoved = newTemplates.map((item) => {
        if (item.crawlers.filter(({ crawlerId }) => crawlerId === idToRemove).length) {
          return {
            ...item,
            crawlers: item.crawlers.filter(
              ({ crawlerId }) => crawlerId !== idToRemove,
            ),
          };
        }

        return item;
      });

      return {
        ...state,
        allTemplates: withRemoved,
        currentTemplate: newCurrentTemplate,
      };
    }

    default:
      return state;
  }
};

export default templates;
