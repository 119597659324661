import React from 'react';
import Portal from '../portal';
import styles from './styles.module.scss';

export default function Modal(props) {
  const {
    open, onClose, children,
  } = props;
  const backdrop = React.useRef(null);

  React.useEffect(() => {
    const { current } = backdrop;

    const keyHandler = (e) => [27].indexOf(e.which) >= 0 && onClose();

    const clickHandler = (e) => e.target === current && onClose();

    if (current) {
      current.addEventListener('click', clickHandler);
      window.addEventListener('keyup', keyHandler);
    }

    if (open) {
      document.activeElement.blur();
      document.body.classList.add('modal-open');
    }

    return () => {
      if (current) {
        current.removeEventListener('click', clickHandler);
      }

      window.removeEventListener('keyup', keyHandler);
      document.body.classList.remove('modal-open');
    };
  }, [open, onClose]);

  return (
    <>
      {open && (
        <Portal className="modal-portal">
          <div ref={backdrop} className={`${styles.backdrop} ${open ? styles.backdropActive : ''}`}>
            <div className={styles.modalContent}>{children}</div>
          </div>
        </Portal>
      )}
    </>
  );
}
