import React from 'react';
import leftArrowIcon from 'assets/icons/left-arrow-icon.svg';
import rightArrowIcon from 'assets/icons/right-arrow-icon.svg';
import styles from './styles.module.scss';

const ParsersPaginationComponent = (props) => {
  const {
    pageNum, totalPages, clkLeft, clkRight,
  } = props;

  return (
    <div className={styles.ppcCont}>
      <div role="button" tabIndex="0" onClick={clkLeft} onKeyDown={clkLeft} className={styles.ppcInnerButton}>
        <img src={leftArrowIcon} alt="left-arr" />
      </div>
      <div className={styles.ppcMiddlePart}>
        <span className={styles.ppcMiddlePart1}>{pageNum}</span>
        <span className={styles.ppcMiddlePart2}>/</span>
        <span className={styles.ppcMiddlePart3}>{totalPages}</span>
      </div>

      <div role="button" tabIndex="0" onClick={clkRight} onKeyDown={clkRight} className={styles.ppcInnerButton}>
        <img src={rightArrowIcon} alt="right-arr" />
      </div>
    </div>
  );
};

export default ParsersPaginationComponent;
