import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

const PublicationTimeItem = (props) => {
  const { message, checked, clkHandler } = props;

  return (
    <div
      className={classNames(styles.container, { [styles.checked]: checked })}
      onClick={() => clkHandler(message)}
      onKeyDown={() => clkHandler(message)}
      role="button"
      tabIndex="0"
    >
      {message.value}
      <FormattedMessage id={`sortingPostsFilter.${message.type}`} />
    </div>
  );
};

export default PublicationTimeItem;
