import React from 'react';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import arrowIcon from 'assets/icons/arrow-icon.svg';
import styles from './styles.module.scss';

const SortByItem = (props) => {
  const {
    text, icon, clkHandler, checked, sortParams, direction,
  } = props;
  const dirVal = parseInt(direction, 10);

  return (
    <div
      className={classNames(styles.container, { [styles.checked]: checked })}
      onClick={() => {
        clkHandler({ field: sortParams, dir: dirVal > 0 ? '1' : '-1' });
      }}
      onKeyDown={() => {
        clkHandler({ field: sortParams, dir: dirVal > 0 ? '1' : '-1' });
      }}
      role="button"
      tabIndex="0"
    >
      <div>
        <img alt="sort_icon" src={icon} />
      </div>
      <span className={styles.text}>
        {text}
      </span>

      <div
        role="button"
        tabIndex="0"
        className={styles.arrowIcon}
        onKeyDown={(e) => {
          clkHandler({ field: sortParams, dir: dirVal > 0 ? '-1' : '1' });
          e.stopPropagation();
        }}
        onClick={(e) => {
          clkHandler({ field: sortParams, dir: dirVal > 0 ? '-1' : '1' });
          e.stopPropagation();
        }}
      >
        <img src={arrowIcon} alt="arrow" className={classNames(styles.filterSourcesArrow, { [styles.rotated]: dirVal > 0 })} />
      </div>
    </div>
  );
};

export default SortByItem;
