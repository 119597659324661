import React from 'react';
import {
  Switch, Route, withRouter,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import messages from 'i18n/en/messages';
import ReactNotification from 'react-notifications-component';
import { addResizeWindowListener } from 'helpers';
import Error404 from './pages/Error404';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import PostsPage from './pages/PostsPage';
import TrendsPage from './pages/TrendsPage';
import CategoryPostsPage from './pages/CategoryPostsPage';
import ModalWrapper from './components/modalWrapper';

addResizeWindowListener();

function App() {
  return (
    <IntlProvider locale="en" messages={messages}>
      <ReactNotification />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/" component={MainPage} />
              <Route exact path="/parser-data/:parserName" component={PostsPage} />
              <Route exact path="/trends/:parserName" component={TrendsPage} />
              <Route exact path="/parser-data/:parserName/:categoryName" component={CategoryPostsPage} />
              <Route component={Error404} />
            </Switch>
          </div>
        </div>
      </div>
      <ModalWrapper />
    </IntlProvider>
  );
}

export default withRouter(App);
