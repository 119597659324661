import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import SortByItem from 'components/SortingPostsFilter/SortByItem';
import { templateDateConvert } from 'helpers';
import Overlay from 'components/Overlay';
import config from 'components/FiltersBar/TimeFilterComponent/time-filter-config';
import { TIME_FILTER_CONFIG } from 'consts';
import SectionsSorting from './SectionsSorting';
import PublicationTimeItem from './PublicationTimeItem';
import styles from './styles.module.scss';

const SortingPostsFilter = (props) => {
  const {
    filterPosts, parserInfo, hidden,
  } = props;
  const [currentSortByItem,
    setCurrentSortByItem] = useState(parserInfo && parserInfo.options.period);
  const [currentPublicationForTime,
    setCurrentPublicationForTime] = useState(parserInfo && parserInfo.options.sortBy);
  const [currentSection, setCurrentSection] = useState('');
  const [show, setShow] = useState(false);
  const namesArr = ['Likes', 'Comments', 'Shares'];
  const { items: publicationTimeStrings } = config;

  useEffect(() => {
    if (show) {
      setCurrentSortByItem(parserInfo.options.sortBy);
      setCurrentPublicationForTime(parserInfo.options.period);
    }
    // eslint-disable-next-line
  }, [show]);

  if (!parserInfo) {
    return null;
  }

  const arrSortOptions = parserInfo.sortingOptions.available;
  const publicationTimeItemHandler = (timeOption) => {
    setCurrentPublicationForTime({
      value: timeOption.value,
      unit: TIME_FILTER_CONFIG[timeOption.type],
    });
  };

  const currentSectionItemHandler = (sectionName) => {
    setCurrentSection(sectionName);
  };

  const sortByItemHandler = (sortParams) => {
    setCurrentSortByItem(sortParams);
  };

  const mainButtonClk = () => {
    setShow(!show);
  };

  const mainButtonKeyDown = () => null;

  const applyFilters = () => {
    const bundle = {
      sortBy: { ...currentSortByItem },
      period: { ...currentPublicationForTime },
    };

    if (currentSection) {
      bundle.sections = [currentSection];
    } else if (parserInfo.options
      && parserInfo.options.sections
      && parserInfo.options.sections
      && parserInfo.options.sections[0]) {
      bundle.sections = parserInfo.options.sections;
    }

    filterPosts(bundle);

    setShow(false);
  };

  const sortByItems = arrSortOptions.map((v) => {
    const iconName = namesArr.includes(v.name) ? `/icons/${v.name}-icon.svg` : '/icons/default-imgs/default-sort-icon.svg';

    return (
      <SortByItem
        key={`sort_by_key_${v.name}${Math.random().toFixed(8)}`}
        text={v.name}
        sortParams={v.field}
        icon={iconName}
        direction={(currentSortByItem && currentSortByItem.field === v.field)
          ? currentSortByItem.dir
          : v.dir}
        checked={currentSortByItem && currentSortByItem.field === v.field}
        clkHandler={sortByItemHandler}
      />
    );
  });

  const publicationTimeItems = publicationTimeStrings.map((v) => {
    const translatedTimeItem = templateDateConvert(currentPublicationForTime);

    return (
      <PublicationTimeItem
        message={v}
        key={`pub_key_${parserInfo.name}_${v.value}_${v.type}`}
        checked={v.value === parseInt(translatedTimeItem.value, 10)
        && v.type === translatedTimeItem.type}
        clkHandler={publicationTimeItemHandler}
      />
    );
  });

  const showFilters = (parserInfo.filters && parserInfo.filters.length > 0);

  return (
    <div className={classNames(styles.sortingPostsFilterCont, { [styles.appear]: hidden })}>
      <div
        role="button"
        tabIndex="0"
        className={classNames(styles.sPFMainButton, { [styles.highLayer]: show })}
        onClick={mainButtonClk}
        onKeyDown={mainButtonKeyDown}
      >
        <FormattedMessage id="sortingPostsFilter.title" />
      </div>
      <div className={classNames(styles.sPFBlock, { [styles.hidden]: show })}>

        <div className={styles.sPFTopPart}>
          <div className={styles.sPFTopPartTitle}>
            <FormattedMessage id="sortingPostsFilter.sortByTitle" />
          </div>
          <div className={styles.sPFTopPartContent}>
            {sortByItems}
          </div>
        </div>

        {showFilters
        && <SectionsSorting handler={currentSectionItemHandler} parserInfo={parserInfo} />}

        <div className={styles.sPFBottomPart}>
          <div className={styles.divider} />
          <div className={styles.sPFBottomPartTitle}>
            <FormattedMessage id="sortingPostsFilter.publicationsForTimeTitle" />
          </div>

          <div className={styles.sPFBottomPartContent}>
            {publicationTimeItems}
          </div>

          <div className={styles.sPFBottomPartApplyButton}>
            <Button
              additionalStyles={styles.sPFAdditionalStyleForApplyButton}
              onClick={applyFilters}
            >
              <FormattedMessage id="sortingPostsFilter.applyButton" />
            </Button>
          </div>
        </div>
      </div>
      <Overlay show={show} clickHandler={(a) => setShow(a)} />
    </div>
  );
};

export default SortingPostsFilter;
