import React from 'react';
import classNames from 'classnames';
import Text from 'components/Text';
import { Link } from 'react-router-dom';
import BadgeContainer from 'components/BadgeContainer';
import CommentsComponent from 'components/CommentsComponent';
import styles from './styles.module.scss';

const PostItemSm = (props) => {
  const {
    additionalStyles = {}, post, parserName, sortingPostfix,
  } = props;
  const {
    link, title, description, category,
  } = post;

  return (
    <div
      className={classNames('col-12 col-md-10 col-lg-8', styles.postItemCont, additionalStyles)}
    >
      <CommentsComponent parserName={parserName} data={post} cn={styles.commentButton} />
      <div className={
        classNames(styles.categoryBlock, { [styles.catogoryBlockHide]: !category.name })
      }
      >

        <Link to={`/parser-data/${parserName}/${encodeURIComponent(category.name)}?${sortingPostfix}`}>

          <Text
            content={category.name}
          />
        </Link>
      </div>
      <a
        rel="noreferrer"
        target="_blank"
        href={link}
        className={styles.postItemRightCont}
      >
        <Text content={title || description} additionalStyles={styles.postItemText} />
        <div className={styles.postItemIndicators} />
        <div className={styles.badgesPart}>
          <BadgeContainer data={post} parserName={parserName} small />
        </div>

      </a>
    </div>
  );
};

export default PostItemSm;
