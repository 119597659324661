import React from 'react';
import classNames from 'classnames';
import Button from 'components/Button';
import { onImageError } from 'helpers';
import styles from './styles.module.scss';

const FilterParserItem = (props) => {
  const {
    item: {
      id,
      title,
      parser,
      selected,
    },
    onChange,
  } = props;
  const imgSrc = `/icons/parsers/${parser}.png`;

  return (
    <Button
      additionalStyles={classNames(styles.btn, {
        [styles.btnSelected]: selected,
      })}
      additionalTextStyles={styles.buttonText}
      onClick={() => onChange(id)}
    >
      <div className={styles.parserIconContainer}>
        <img src={imgSrc} alt="parser-icon" onError={(e) => onImageError(e, 20)} />
      </div>
      <div className={styles.nameContainer}>
        {title}
      </div>
    </Button>
  );
};

export default FilterParserItem;
