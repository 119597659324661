import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const BadgeItem = (props) => {
  const {
    iconType, tooltipText, value = '', infoType = false, small = false,
  } = props;

  if (!parseFloat(value) && !infoType) {
    return null;
  }

  return (
    <div className={classNames(styles.badgeItemContainer, { [styles.infoTypeStyle]: infoType })}>
      <div className={styles.badgeItemIconCont}>
        <img
          alt="badge_altimg"
          src={`/icons/badge/badge-${iconType}.svg`}
        />
      </div>
      <div className={styles.badgeText}>
        {value}
      </div>

      <div className={
        classNames(styles.tooltipCont,
          { [styles.infoTypeTooltip]: infoType },
          { [styles.infoTypeTooltipSm]: small })
}
      >
        <div className={styles.tooltipText}>
          {tooltipText}
        </div>
      </div>
    </div>
  );
};

export default BadgeItem;
